import { departmentsAPI } from 'api/departments';
import { ColumnChartParams } from 'components/columnChart/columnChartTypes';
import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { DashboardData } from 'mobx/storeTypes';
import { getAllDepartmentsStatisticsDataAdapted } from './_adapters';
export class AllDepartmentsStore {
  public rootStore: RootStore;
  public allDepartmentsStatistics: DashboardData.AllDepartmentInfoList | null =
    null;
  public allDepartmentsAdaptedData: ColumnChartParams | null = null;
  public isDataFetching = false;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public async fetchAllDepartmentsData(filterByDepartmentName?: string) {
    try {
      this._setIsDataFetching(true);
      const res = await departmentsAPI.getDepartmentsStatistics();

      this._setDepartmentsStatistics(res);

      const adaptedData = getAllDepartmentsStatisticsDataAdapted(
        res,
        filterByDepartmentName
      );
      this._setAdaptedData(adaptedData);
      this._setIsDataFetching(false);
    } catch (e) {
      console.error(e);
    }
  }

  private _setIsDataFetching(val: boolean) {
    this.isDataFetching = val;
  }

  private _adaptData() {
    if (this.allDepartmentsStatistics) {
      const adaptedData = getAllDepartmentsStatisticsDataAdapted(
        this.allDepartmentsStatistics
      );
      this._setAdaptedData(adaptedData);
    }
  }

  private _setDepartmentsStatistics(
    statistics: DashboardData.AllDepartmentInfoList
  ) {
    this.allDepartmentsStatistics = statistics;
  }

  private _setAdaptedData(data: ColumnChartParams) {
    this.allDepartmentsAdaptedData = data;
  }

  public filterChartSeriesByPeriod() {
    this._adaptData();
    const newSeries = this.allDepartmentsAdaptedData?.series;

    const enabledSeries = this.rootStore.periodFilter.enabledSeries;
    const filteredSeries =
      newSeries &&
      newSeries.filter((item) => {
        return enabledSeries.includes(item.name as string);
      });

    const newAdaptedData = {
      series: filteredSeries,
      axisParams: this.allDepartmentsAdaptedData?.axisParams,
    } as ColumnChartParams;

    this._setAdaptedData(newAdaptedData);
  }
}
