import React, { useContext } from 'react';
import { ListItem, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';
import { isElementOdd } from 'utils/helpers';

export default function ListItemWrapper({
  children,
  isSidePaddingEnabled,
  index,
  bgColor,
}: {
  children: React.ReactNode;
  isSidePaddingEnabled?: boolean;
  index: number;
  bgColor?: string;
}) {
  const theme = useTheme();

  const {
    spacings: { sp },
    isMobile,
  } = useContext(UiContext);

  const bgColorCalculated = isElementOdd(index)
    ? undefined
    : theme.palette.customBackgrounds.darkBlack;

  const backgroundColor = bgColor || bgColorCalculated;

  const paddingValue = isMobile ? sp.s120 : sp.s240;

  return (
    <ListItem
      sx={{
        width: 1,
        color: theme.palette.custom.white,
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        paddingLeft: isSidePaddingEnabled ? paddingValue : undefined,
        paddingRight: isSidePaddingEnabled ? paddingValue : undefined,
        paddingTop: sp.s100,
        paddingBottom: sp.s100,
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </ListItem>
  );
}
