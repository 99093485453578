import React from 'react';
import UiWrapper from 'uiWrapper';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PerformanceReviewPage from 'pages/performanceReviewPage/performanceReviewPage';
import EmployeeReviewResultsPage from 'pages/employeeReviewResultsPage/employeeReviewResultsPage';
import * as Highcharts from 'highcharts';
import * as HighchartsAccessibility from 'highcharts/modules/accessibility';
import FailedAuthorizationPage from 'pages/failedAuthorizationPage/failedAuthorizationPage';
// @ts-ignore
HighchartsAccessibility(Highcharts);

export default function App() {
  return (
    <Router>
      <UiWrapper>
        <Routes>
          <Route path="/" element={<PerformanceReviewPage />} />
          <Route path="/department/:id" element={<PerformanceReviewPage />} />
          <Route
            path={`/sub_department/:id`}
            element={<PerformanceReviewPage />}
          />
          <Route path={`/employee/:id`} element={<PerformanceReviewPage />} />
          <Route
            path={`/employee_reviews_list/:id`}
            element={<PerformanceReviewPage />}
          />
          <Route
            path="/employee_review"
            element={<EmployeeReviewResultsPage />}
          />
          <Route path="/auth_fail" element={<FailedAuthorizationPage />} />
        </Routes>
      </UiWrapper>
    </Router>
  );
}
