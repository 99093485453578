import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconChat({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconChat', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_52_4816)">
        <path
          d="M3.3335 36.6668V6.66683C3.3335 5.75016 3.65989 4.96544 4.31266 4.31266C4.96544 3.65989 5.75016 3.3335 6.66683 3.3335H33.3335C34.2502 3.3335 35.0349 3.65989 35.6877 4.31266C36.3404 4.96544 36.6668 5.75016 36.6668 6.66683V26.6668C36.6668 27.5835 36.3404 28.3682 35.6877 29.021C35.0349 29.6738 34.2502 30.0002 33.3335 30.0002H10.0002L3.3335 36.6668ZM8.5835 26.6668H33.3335V6.66683H6.66683V28.5418L8.5835 26.6668Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
