import { ThemeVariant } from './storeTypes';
import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { constructTheme } from 'utils/constructTheme';

export class UiStore {
  public rootStore: RootStore;

  public currentThemeVariant = constructTheme(ThemeVariant.DARK);

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  public setCurrentThemeVariant(variant: ThemeVariant) {
    this.currentThemeVariant = constructTheme(variant);
  }
}
