import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import MobxContextWrapper from 'mobx/mobxContextWrapper';

ReactDOM.render(
  <MobxContextWrapper>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </MobxContextWrapper>,
  document.getElementById('root')
);
