import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconArrowRight({
  onClick,
  isAutoscallable = true,
  sizeKey = '20x20',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconArrowRight', {
        'icon-disabled': !onClick,
      })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43399 7.58339L2.33366 7.58339V6.41672H9.43399L6.30499 3.28772L7.12983 2.46289L11.667 7.00006L7.12983 11.5372L6.30499 10.7124L9.43399 7.58339Z"
        fill="#F5F5F5"
      />
    </svg>
  );
}
