import { axiosV1 } from 'api/axios';
import { EmployeeFormData } from 'mobx/storeTypes';

export const employeeFormAPI = {
  getTotalForm: async (employeeId: number, reviewPeriodId: number) => {
    const res = await axiosV1.get<EmployeeFormData.TotalForm>(
      `/report/total_form/${employeeId}`,
      {
        params: {
          review_period_id: reviewPeriodId,
        },
      }
    );

    return res.data;
  },
  getReviewPeriods: async (employeeId: number) => {
    const res = await axiosV1.get<EmployeeFormData.ReviewPeriod[]>(
      '/report/review_periods',
      {
        params: { employee_id: employeeId },
      }
    );

    return res.data;
  },
  download: async (employeeId: number, reviewPeriodId: number) => {
    const resName = await axiosV1.get<{ filename: string }>(
      `/report/total_form/${employeeId}/filename`,
      {
        params: {
          review_period_id: reviewPeriodId,
        },
      }
    );

    const res = await axiosV1.get<Blob>(
      `/report/total_form/${employeeId}/file`,
      {
        params: {
          review_period_id: reviewPeriodId,
        },
        responseType: 'blob',
      }
    );

    const reader = new FileReader();
    reader.onload = function (e) {
      const src = e?.target?.result as string;

      if (!src) {
        return;
      }
      const link = document.createElement('a');
      link.href = src;
      link.download = resName.data.filename;

      document.body.appendChild(link);
      link.click();
    };
    reader.readAsDataURL(res.data);
  },
};
