import { axiosV1 } from 'api/axios';
import { DashboardData } from 'mobx/storeTypes';

export const departmentsAPI = {
  getReviewFilterPeriods: async () => {
    const res = await axiosV1.get<DashboardData.ReviewPeriod[]>(
      '/review_periods?is_active=False'
    );

    return res.data;
  },

  getDepartmentsStatistics: async () => {
    const res = await axiosV1.get<DashboardData.AllDepartmentInfoList>(
      '/statistics/departments'
    );

    return res.data;
  },

  getDepartmentStatisticsById: async (departmentId: number) => {
    const res = await axiosV1.get<DashboardData.DepartmentInfoList>(
      `/statistics/departments/${departmentId}`
    );

    return res.data;
  },

  getSubDepartmentStatisticsById: async (subDepartmentId: number) => {
    const res = await axiosV1.get<DashboardData.SubDepartmentInfoList>(
      `/statistics/sub_departments/${subDepartmentId}`
    );

    return res.data;
  },

  getEmployeeStatisticsById: async (employeeId: number) => {
    const res = await axiosV1.get<DashboardData.EmployeeInfo>(
      `/statistics/employees/${employeeId}`
    );

    return res.data;
  },
};
