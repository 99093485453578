import React from 'react';
import { Box, Typography } from '@mui/material';

export default function RegularTooltip({ date }: { date: string }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="f14">Подробнее</Typography>
      <Typography variant="f14">{date}</Typography>
    </Box>
  );
}
