import React from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import Header from 'components/header/header';

export default function PageHolder({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.custom.white,
        ...sx,
      }}
    >
      <Header sizeKey="38x38" />

      {children}
    </Box>
  );
}
