import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { UiContext } from 'uiContext';
import ArchiveListItem from './archiveListItem';
import { map } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { formatDateToPeriod } from 'utils/dateFormat';

export default observer(function EmployeeReviewsArchive() {
  const {
    spacings: { sp },
  } = useContext(UiContext);

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { reviewPeriods } = employeeFormStore;

  return (
    <Box
      sx={{
        marginTop: sp.s120,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: sp.s60,
        paddingRight: sp.s60,
        minHeight: '60px',
        gap: sp.s240,
      }}
    >
      {map(reviewPeriods, ({ end_date, id, file_id }) => (
        <ArchiveListItem
          key={id}
          periodId={id}
          periodDate={formatDateToPeriod(end_date)}
          periodFileId={file_id || ''}
        />
      ))}
    </Box>
  );
});
