import { departmentsAPI } from 'api/departments';
import { ColumnChartParams } from 'components/columnChart/columnChartTypes';
import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { DashboardData } from 'mobx/storeTypes';
import { getEmployeeInfoDataAdapted } from './_adapters';
export class EmployeeStore {
  public rootStore: RootStore;
  public currentEmployeeId: number | null = null;
  public currentEmployeeName: string | null = null;
  public employeeInfo: DashboardData.EmployeeInfo | null = null;
  public adaptedEmployeeData: ColumnChartParams | null = null;
  public isDataFetching = false;
  public isReviewsListCurrentRoute = false;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public setCurrentEmployeeId(id: number | null) {
    this.currentEmployeeId = id;
  }

  public setCurrentEmployeeName(value: string | null) {
    this.currentEmployeeName = value;
  }

  public setEmployeeInfo(data: DashboardData.EmployeeInfo) {
    this.employeeInfo = data;
  }

  public async fetchEmployeeData(id: number, employeeName: string) {
    this.setCurrentEmployeeId(id);
    this.setCurrentEmployeeName(employeeName);

    this.rootStore.periodFilter.resetAllPeriodStatusesToActive();

    try {
      this._setIsDataFetching(true);
      const res = await departmentsAPI.getEmployeeStatisticsById(id);

      this.setEmployeeInfo(res);
      this._adaptData();
      this._setIsDataFetching(false);
    } catch (e) {
      console.error(e);
    }
  }

  private _setIsDataFetching(val: boolean) {
    this.isDataFetching = val;
  }

  private _setAdaptedData(data: ColumnChartParams) {
    this.adaptedEmployeeData = data;
  }

  private _adaptData() {
    if (this.employeeInfo) {
      const data = getEmployeeInfoDataAdapted(this.employeeInfo);
      this._setAdaptedData(data);
    }
  }

  public filterChartSeriesByPeriod() {
    this._adaptData();
    const newSeries = this.adaptedEmployeeData?.series;

    const enabledSeries = this.rootStore.periodFilter.enabledSeries;
    const filteredSeries =
      newSeries &&
      newSeries.filter((item) => {
        return enabledSeries.includes(item.name as string);
      });

    const newAdaptedData = {
      series: filteredSeries,
      axisParams: this.adaptedEmployeeData?.axisParams,
    } as ColumnChartParams;

    this._setAdaptedData(newAdaptedData);
  }

  public setIsReviewsListCurrentRoute(value: boolean) {
    this.isReviewsListCurrentRoute = value;
  }

  public clearEmployeeInfo() {
    this.currentEmployeeId = null;
    this.currentEmployeeName = null;
    this.isReviewsListCurrentRoute = false;
  }
}
