import { departmentsAPI } from 'api/departments';
import { ColumnChartParams } from 'components/columnChart/columnChartTypes';
import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { DashboardData } from 'mobx/storeTypes';
import { getDepartmentDataAdapted } from './_adapters';

export class DepartmentStore {
  public rootStore: RootStore;
  public currentDepartmentId: number | null = null;
  public departmentInfo: DashboardData.DepartmentInfoList | null = null;
  public adaptedDepartmentData: ColumnChartParams | null = null;
  public currentDepartmentName: string | null = null;
  public isDataFetching = false;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public setCurrentDepartmentId(id: number | null) {
    this.currentDepartmentId = id;
  }

  public async fetchDepartmentData(
    id: number,
    departmentName: string,
    filterBySubDepartmentName?: string
  ) {
    this.setCurrentDepartmentId(id);
    this.setCurrentDepartmentName(departmentName);

    this.rootStore.periodFilter.resetAllPeriodStatusesToActive();

    try {
      this._setIsDataFetching(true);
      const res = await departmentsAPI.getDepartmentStatisticsById(id);

      this.setDepartmentInfo(res);
      this._adaptData(filterBySubDepartmentName);
      this._setIsDataFetching(false);
    } catch (e) {
      console.error(e);
    }
  }

  private _setIsDataFetching(val: boolean) {
    this.isDataFetching = val;
  }

  public setDepartmentInfo(data: DashboardData.DepartmentInfoList) {
    this.departmentInfo = data;
  }

  private _adaptData(filterByDepartmentName?: string) {
    if (this.departmentInfo) {
      const data = getDepartmentDataAdapted(
        this.departmentInfo,
        filterByDepartmentName
      );

      this._setAdaptedData(data);
    }
  }

  private _setAdaptedData(data: ColumnChartParams) {
    this.adaptedDepartmentData = data;
  }

  public setCurrentDepartmentName(value: string | null) {
    this.currentDepartmentName = value;
  }

  public filterChartSeriesByPeriod() {
    this._adaptData();
    const newSeries = this.adaptedDepartmentData?.series;

    const enabledSeries = this.rootStore.periodFilter.enabledSeries;
    const filteredSeries =
      newSeries &&
      newSeries.filter((item) => {
        return enabledSeries.includes(item.name as string);
      });

    const newAdaptedData = {
      series: filteredSeries,
      axisParams: this.adaptedDepartmentData?.axisParams,
    } as ColumnChartParams;

    this._setAdaptedData(newAdaptedData);
  }

  public clearDepartmentInfo() {
    this.currentDepartmentId = null;
    this.currentDepartmentName = null;
  }
}
