import { makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { NOTIFICATION_HIDE_TIMEOUT } from 'utils/constants';
import { Auth } from 'mobx/storeTypes';
import { setToken } from 'api/axios';

export class AuthInfoStore {
  public rootStore: RootStore;
  public authError = false;
  public authInfo: Auth.AuthInfo | null = null;

  public isNotificationOpened = false;
  public initialRedirectDone = false;
  public timeoutId: number | null = null;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    const searchParams = new URLSearchParams(window.location.search);

    const errorMessage = searchParams.get('error_message');
    if (errorMessage) {
      this._setAuthError(true);
    } else {
      // Если зашли на страницу в браузере либо перезагрузили страницу
      if (searchParams.size === 0) {
        let previousAuthInfo = {} as Auth.AuthInfo;
        const lsItem = localStorage.getItem('authInfo');

        if (lsItem) {
          previousAuthInfo = JSON.parse(lsItem) as Auth.AuthInfo;
        }

        localStorage.setItem('authInfo', JSON.stringify(previousAuthInfo));
        this.authInfo = previousAuthInfo;

        this._setToken();
      } else {
        let authInfo = {} as Auth.AuthInfo;
        for (const [key, value] of searchParams.entries()) {
          authInfo[key as keyof Auth.AuthInfo] = value;
        }

        localStorage.setItem('authInfo', JSON.stringify(authInfo));
        this.authInfo = authInfo;

        this._setToken();
      }
    }

    makeAutoObservable(this);
  }

  private _setToken() {
    if (this.authInfo?.token) {
      setToken(this.authInfo.token);
    }
  }

  private _setAuthError(value: boolean) {
    this.authError = value;
  }

  public setInitialRedirectDone(value: boolean) {
    this.initialRedirectDone = value;
  }

  public setIsNotificationOpened(value: boolean) {
    this.isNotificationOpened = value;

    const timeoutId = setTimeout(
      () => {
        runInAction(() => {
          this.isNotificationOpened = false;
        });
      },
      NOTIFICATION_HIDE_TIMEOUT,
      this
    );

    this.setTimeoutId(timeoutId);
  }

  public setTimeoutId(timeoutId: number | null) {
    this.timeoutId = timeoutId;
  }
}
