import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import IconVerification from 'icons/verification';
import { UiContext } from 'uiContext';
import PageHolder from 'components/pageHolder/pageHolder';

export default function FailedAuthorizationPage() {
  const {
    spacings: { sp },
    isMobile,
  } = useContext(UiContext);

  return (
    <PageHolder
      sx={{
        height: '100vh',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 1,
          height: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <IconVerification />
          </Box>
          <Box
            sx={{
              marginTop: sp.s60,
              maxWidth: isMobile ? '60%' : 1,
              textAlign: 'center',
            }}
          >
            <Typography variant="f24" sx={{ wordBreak: 'break-word' }}>
              Вы можете авторизоваться через меню телеграмм-бота Марк
            </Typography>
          </Box>
        </Box>
      </Box>
    </PageHolder>
  );
}
