import React, { useContext, useEffect } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import PeriodFilter from 'components/periodFilter/periodFilter';
import PageHeader from 'components/pageHeader/pageHeader';
import AllDepartmentsChart from 'components/allDepartmentsChart/allDepartmentsChart';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DepartmentChart from 'components/departmentChart/departmentChart';
import SubDepartmentChart from 'components/subDepartmentChart/subDepartmentChart';
import EmployeeChart from 'components/employeeChart/employeeChart';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import EmptyChart from 'components/emptyChart/emptyChart';
import IconArrowRight from 'icons/arrowRight';
import { UiContext } from 'uiContext';
import EmployeeReviewsArchive from 'components/employeeReviewsArchive/employeeReviewsArchive';
import Notification from 'components/notification/notification';
import { Auth } from 'mobx/storeTypes';
import PageHolder from 'components/pageHolder/pageHolder';

export default observer(function PerformanceReviewPage() {
  const theme = useTheme();

  const {
    spacings: { sp },
  } = useContext(UiContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mobxStore: {
      allDepartmentsStore,
      departmentStore,
      subDepartmentStore,
      employeeStore,
      authInfoStore,
    },
  } = useContext(MobxContext);

  const { allDepartmentsAdaptedData } = allDepartmentsStore;
  const { adaptedDepartmentData, currentDepartmentId } = departmentStore;
  const { adaptedSubDepartmentData } = subDepartmentStore;
  const { adaptedEmployeeData, currentEmployeeId } = employeeStore;
  const { isNotificationOpened, authError, authInfo, initialRedirectDone } =
    authInfoStore;

  useEffect(() => {
    if (authError || !authInfo?.token) {
      navigate('/auth_fail');
      return;
    }

    if (!initialRedirectDone) {
      setSearchParams({} as URLSearchParams);

      switch (authInfo?.role) {
        case Auth.Roles.HR:
        case Auth.Roles.Head:
          allDepartmentsStore.fetchAllDepartmentsData();
          break;
        case Auth.Roles.DepartmentHead:
          allDepartmentsStore.fetchAllDepartmentsData(authInfo.department);
          break;
        case Auth.Roles.Employee:
        case Auth.Roles.Newbie:
          subDepartmentStore.setCurrentSubDepartmentId(
            authInfo.sub_department_id
          );
          subDepartmentStore.setCurrentSubDepartmentName(
            authInfo.sub_department_name
          );
          departmentStore.setCurrentDepartmentName(authInfo.department);
          departmentStore.setCurrentDepartmentId(authInfo.department_id);
          employeeStore.fetchEmployeeData(authInfo.user_id, authInfo.fullname);
          navigate(`/employee/${authInfo.user_id}`);
          break;
        case Auth.Roles.SubDepartmentHead:
          departmentStore.fetchDepartmentData(
            authInfo.department_id,
            authInfo.department,
            authInfo.sub_department_name
          );
          navigate(`/department/${authInfo.department_id}`);
          break;
        default:
          throw new Error('Неизвестная роль пользователя');
      }

      authInfoStore.setInitialRedirectDone(true);
    }
  }, [
    authInfoStore,
    allDepartmentsStore,
    authError,
    authInfo,
    currentDepartmentId,
    departmentStore,
    employeeStore,
    navigate,
    searchParams,
    setSearchParams,
    subDepartmentStore,
    initialRedirectDone,
  ]);

  const allDepartmentsChart =
    allDepartmentsAdaptedData?.series &&
    allDepartmentsAdaptedData.series.length > 0 ? (
      <AllDepartmentsChart />
    ) : allDepartmentsStore.isDataFetching ? null : (
      <EmptyChart />
    );

  const departmentChart =
    adaptedDepartmentData?.series && adaptedDepartmentData.series.length > 0 ? (
      <DepartmentChart />
    ) : departmentStore.isDataFetching ? null : (
      <EmptyChart />
    );

  const subDepartmentChart =
    adaptedSubDepartmentData?.series &&
    adaptedSubDepartmentData.series.length > 0 ? (
      <SubDepartmentChart />
    ) : subDepartmentStore.isDataFetching ? null : (
      <EmptyChart />
    );

  const employeeChart =
    adaptedEmployeeData?.series && adaptedEmployeeData.series.length > 0 ? (
      <EmployeeChart />
    ) : employeeStore.isDataFetching ? null : (
      <EmptyChart />
    );

  const handleEmployeeFormClick = () => {
    employeeStore.setIsReviewsListCurrentRoute(true);
    navigate(`/employee_reviews_list/${currentEmployeeId}`);
  };

  return (
    <PageHolder
      sx={{
        flexGrow: 1,
        width: '100vw',
      }}
    >
      <PageHeader />

      <PeriodFilter />

      {location.pathname === '/' && allDepartmentsChart}
      {location.pathname.includes('/department/') && departmentChart}
      {location.pathname.includes('/sub_department/') && subDepartmentChart}
      {location.pathname.includes('/employee/') && (
        <>
          {employeeChart}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{
                'background': theme.palette.custom.infoCardHeaderColor,
                'boxShadow': 'none',
                'color': theme.palette.custom.fontColor,
                '&:hover': {
                  boxShadow: 'none',
                  filter: 'brightness(95%)',
                },
              }}
              onClick={handleEmployeeFormClick}
            >
              <Typography sx={{ marginRight: sp.s10 }}>
                Перейти в подробную анкету сотрудника
              </Typography>
              <IconArrowRight />
            </Button>
          </Box>
        </>
      )}
      {location.pathname.includes('/employee_reviews_list/') && (
        <EmployeeReviewsArchive />
      )}
      <Notification
        open={isNotificationOpened}
        message="Нет прав просмотреть данные"
      />
    </PageHolder>
  );
});
