import { UiStore } from 'mobx/uiStore';
import { PeriodFilter } from './dashboards/periodFilter/periodFilter';
import { AllDepartmentsStore } from './dashboards/allDepartmentStore/allDepartmentsStore';
import { DepartmentStore } from './dashboards/departmentStore/departmentStore';
import { SubDepartmentStore } from './dashboards/subDepartmentStore/subDepartmentStore';
import { EmployeeStore } from './dashboards/employeeStore/employeeStore';
import { EmployeeFormStore } from './employeeForm/employeeFormStore';
import { AuthInfoStore } from './authInfoStore/authInfoStore';

export class RootStore {
  uiStore = new UiStore({ rootStore: this });
  periodFilter = new PeriodFilter({ rootStore: this });
  allDepartmentsStore = new AllDepartmentsStore({ rootStore: this });
  departmentStore = new DepartmentStore({ rootStore: this });
  subDepartmentStore = new SubDepartmentStore({ rootStore: this });
  employeeStore = new EmployeeStore({ rootStore: this });
  employeeFormStore = new EmployeeFormStore({ rootStore: this });
  authInfoStore = new AuthInfoStore({ rootStore: this });

  constructor() {
    this.periodFilter.init();
  }
}

const rootStore = new RootStore();
export default rootStore;
