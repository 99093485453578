import React from 'react';
import { ThemeOptions } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { borderRadiusValues, DEFAULT_ELEVATION } from '../../utils/uiConstants';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      white: string;
      headerBgColor: string;
      fontColor: string;
      fontColorNotActive: string;
      vinousColor: string;
      blue: string;
      turquoise: string;
      green: string;
      defaultButton: string;
      black: string;
      infoCardShadow: string;
      infoCardHeaderColor: string;
      paperBgColor: string;
      chartPlotBand: string;
      purple: string;
      lightGray: string;
      darkGray: string;
      purpleDark: string;
    };
    customBackgrounds: {
      elevation4: string;
      elevation5: string;
      elevation6: string;
      contrast: string;
      darkBlack: string;
      darkGray: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      white: string;
      headerBgColor: string;
      fontColor: string;
      fontColorNotActive: string;
      vinousColor: string;
      blue: string;
      turquoise: string;
      green: string;
      defaultButton: string;
      black: string;
      infoCardShadow: string;
      infoCardHeaderColor: string;
      paperBgColor: string;
      chartPlotBand: string;
      purple: string;
      lightGray: string;
      darkGray: string;
      purpleDark: string;
    };
    customBackgrounds?: {
      elevation4: string;
      elevation5: string;
      elevation6: string;
      contrast: string;
      darkBlack: string;
    };
  }

  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop_small: true;
    desktop_middle: true;
    desktop_large: true;
  }

  // Типография
  interface TypographyVariants {
    f8: React.CSSProperties;
    f8a: React.CSSProperties;
    f10: React.CSSProperties;
    f10a: React.CSSProperties;
    f12: React.CSSProperties;
    f12a: React.CSSProperties;
    f14: React.CSSProperties;
    f14a: React.CSSProperties;
    f16: React.CSSProperties;
    f16a: React.CSSProperties;
    f18: React.CSSProperties;
    f18a: React.CSSProperties;
    f20: React.CSSProperties;
    f20a: React.CSSProperties;
    f22: React.CSSProperties;
    f22a: React.CSSProperties;
    f24: React.CSSProperties;
    f24a: React.CSSProperties;
    f26: React.CSSProperties;
    f26a: React.CSSProperties;
    f28: React.CSSProperties;
    f28a: React.CSSProperties;
    f30: React.CSSProperties;
    f30a: React.CSSProperties;
    f38: React.CSSProperties;
    f38a: React.CSSProperties;
    f40: React.CSSProperties;
    f40a: React.CSSProperties;
    f50: React.CSSProperties;
    f50a: React.CSSProperties;
    f70: React.CSSProperties;
    f70a: React.CSSProperties;
    f80: React.CSSProperties;
    f80a: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    f8: React.CSSProperties;
    f8a: React.CSSProperties;
    f10: React.CSSProperties;
    f10a: React.CSSProperties;
    f12: React.CSSProperties;
    f12a: React.CSSProperties;
    f14: React.CSSProperties;
    f14a: React.CSSProperties;
    f16: React.CSSProperties;
    f16a: React.CSSProperties;
    f18: React.CSSProperties;
    f18a: React.CSSProperties;
    f20: React.CSSProperties;
    f20a: React.CSSProperties;
    f22: React.CSSProperties;
    f22a: React.CSSProperties;
    f24: React.CSSProperties;
    f24a: React.CSSProperties;
    f26: React.CSSProperties;
    f26a: React.CSSProperties;
    f28: React.CSSProperties;
    f28a: React.CSSProperties;
    f30: React.CSSProperties;
    f30a: React.CSSProperties;
    f38: React.CSSProperties;
    f38a: React.CSSProperties;
    f40: React.CSSProperties;
    f40a: React.CSSProperties;
    f50: React.CSSProperties;
    f50a: React.CSSProperties;
    f70: React.CSSProperties;
    f70a: React.CSSProperties;
    f80: React.CSSProperties;
    f80a: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    f8: true;
    f8a: true;
    f10: true;
    f10a: true;
    f12: true;
    f12a: true;
    f14: true;
    f14a: true;
    f16: true;
    f16a: true;
    f18: true;
    f18a: true;
    f20: true;
    f20a: true;
    f22: true;
    f22a: true;
    f24: true;
    f24a: true;
    f26: true;
    f26a: true;
    f28: true;
    f28a: true;
    f30: true;
    f30a: true;
    f38: true;
    f38a: true;
    f40: true;
    f40a: true;
    f50: true;
    f50a: true;
    f70: true;
    f70a: true;
    f80: true;
    f80a: true;
  }
}

export const defaultPalette: PaletteOptions = {
  success: {
    main: '#75B348',
  },
  warning: {
    main: '#FFC914',
  },
  error: {
    main: '#DC5648',
  },
} as const;

export const lightThemeParams: ThemeOptions = {
  palette: {
    text: {
      primary: '#f5f5f5',
    },
    primary: {
      main: '#1AB2E9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#3A86FF',
    },
    custom: {
      white: '#ffffff',
      headerBgColor: '#251e2d',
      fontColor: '#f5f5f5',
      fontColorNotActive: '#615A69',
      chartPlotBand: '#393241',
      vinousColor: '#dd1c9b',
      blue: '#5438dc',
      turquoise: '#3badef',
      green: '#0cc095',
      defaultButton: '#393241',
      black: '#333333',
      infoCardShadow:
        'drop-shadow(0 0 0 rgba(83,83,83,0.1)) drop-shadow(0 9px 19px rgba(83,83,83,0.1))',
      infoCardHeaderColor: 'linear-gradient(to bottom, #803DCB, #582394)',
      paperBgColor: '#292231',
      purple: '#7C28E3',
      lightGray: '#f4f4f4',
      darkGray: '#393241',
      purpleDark: '#955CD6',
    },
    customBackgrounds: {
      elevation4: '#F2F2F2',
      elevation5: 'white',
      elevation6: '#F2F2F2',
      contrast: 'black',
      darkBlack: '#2A2332',
    },
    ...defaultPalette,
  },
};
export const darkThemeParams: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#1AB2E9',
      contrastText: '#1F2933',
    },
    secondary: {
      main: '#3A86FF',
    },
    background: {
      default: '#2f2837',
      paper: '#1F2933',
    },
    text: {
      secondary: '#536D88',
    },
    customBackgrounds: {
      elevation4: '#1B242C', // Ниже основного - темнее
      elevation5: '#1F2933', // Считается как основной уровень, должен совпадать с background.paper, так же см components ниже
      elevation6: '#24303B', // Выше - светлее
      contrast: 'white', // Цвет контрастный всем основным фонам
      darkBlack: '#2A2332',
    },
    custom: {
      white: '#ffffff',
      headerBgColor: '#251e2d',
      fontColor: '#f5f5f5',
      fontColorNotActive: '#615A69',
      vinousColor: '#dd1c9b',
      blue: '#5438dc',
      turquoise: '#3badef',
      green: '#0cc095',
      defaultButton: '#393241',
      black: '#333333',
      infoCardShadow:
        'drop-shadow(0 0 0 rgba(83,83,83,0.1)) drop-shadow(0 9px 19px rgba(83,83,83,0.1))',
      infoCardHeaderColor: 'linear-gradient(to bottom, #803DCB, #582394)',
      paperBgColor: '#292231',
      chartPlotBand: '#393241',
      purple: '#7C28E3',
      lightGray: '#f4f4f4',
      darkGray: '#393241',
      purpleDark: '#955CD6',
    },
    ...defaultPalette,
  },
};

export const commonThemeParams: ThemeOptions = {
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop_small: 1280,
      desktop_middle: 1440,
      desktop_large: 1920,
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'MoscowSans',
    button: {
      fontSize: '0.75rem',
      lineHeight: 'normal',
    },
    f8: {
      fontSize: '0.5rem',
      lineHeight: 'normal',
    },
    f8a: {
      fontSize: '0.5rem',
      lineHeight: 'normal',
    },
    f10: {
      fontSize: '0.625rem',
      lineHeight: 'normal',
    },
    f10a: {
      fontSize: '0.625rem',
      lineHeight: 'normal',
    },
    f12: {
      fontSize: '0.75rem',
      lineHeight: 'normal',
    },
    f12a: {
      fontSize: '0.75rem',
      lineHeight: 'normal',
    },
    f14: {
      fontSize: '0.875rem',
      lineHeight: 'normal',
    },
    f14a: {
      fontSize: '0.875rem',
      lineHeight: 'normal',
    },
    f16: {
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    f16a: {
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    f18: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f18a: {
      fontSize: '1.125rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f20: {
      fontSize: '1.25rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f20a: {
      fontSize: '1.25rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f22: {
      fontSize: '1.375rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f22a: {
      fontSize: '1.375rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f24: {
      fontSize: '1.5rem',
      lineHeight: 'normal',
    },
    f24a: {
      fontSize: '1.5rem',
      lineHeight: 'normal',
    },
    f26: {
      fontSize: '1.625rem',
      lineHeight: 'normal',
    },
    f26a: {
      fontSize: '1.625rem',
      lineHeight: 'normal',
    },
    f28: {
      fontSize: '1.75rem',
      lineHeight: 'normal',
    },
    f28a: {
      fontSize: '1.75rem',
      lineHeight: 'normal',
    },
    f30: {
      fontSize: '1.875rem',
      lineHeight: 'normal',
    },
    f30a: {
      fontSize: '1.875rem',
      lineHeight: 'normal',
    },
    f38: {
      fontSize: '2.875rem',
      lineHeight: 'normal',
    },
    f38a: {
      fontSize: '2.875rem',
      lineHeight: 'normal',
    },
    f40: {
      fontSize: '3rem',
      lineHeight: 'normal',
    },
    f40a: {
      fontSize: '3rem',
      lineHeight: 'normal',
    },
    f50: {
      fontSize: '4.125rem',
      lineHeight: 'normal',
    },
    f50a: {
      fontSize: '4.125rem',
      lineHeight: 'normal',
    },
    f70: {
      fontSize: '4.875rem',
      lineHeight: 'normal',
    },
    f70a: {
      fontSize: '4.875rem',
      fontWeight: 'normal',
      lineHeight: 'normal',
    },
    f80: {
      fontSize: '6rem',
      lineHeight: 'normal',
    },
    f80a: {
      fontSize: '6rem',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiPaper: {
      // За основу берем значение 5 чтобы был запас для маневров
      defaultProps: {
        elevation: DEFAULT_ELEVATION,
      },
      styleOverrides: {
        // Вырубаем стандартное поведение, чтобы определить точно в соответствии с макетом
        elevation: {
          boxShadow: 'none !important',
          backgroundImage: 'none !important',
        },
        elevation6: ({ theme }) => {
          return {
            backgroundColor: theme.palette.customBackgrounds.elevation6,
          };
        },
        elevation5: ({ theme }) => {
          return {
            backgroundColor: theme.palette.customBackgrounds.elevation5,
          };
        },
        elevation4: ({ theme }) => {
          return {
            backgroundColor: theme.palette.customBackgrounds.elevation4,
          };
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: DEFAULT_ELEVATION,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'elipsis',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeLarge: ({ theme }) => ({
          borderRadius:
            borderRadiusValues.medium * Number(theme.shape.borderRadius),
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
        }),
        sizeMedium: ({ theme }) => ({
          borderRadius:
            borderRadiusValues.small * Number(theme.shape.borderRadius),
          paddingTop: theme.spacing(2.5),
          paddingBottom: theme.spacing(2.5),
        }),
        sizeSmall: ({ theme }) => ({
          borderRadius:
            borderRadiusValues.XSmall * Number(theme.shape.borderRadius),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            gap: `${theme.spacing(1)}`,
          };
        },
      },
    },
  },
};

export const defaultThemeParams: ThemeOptions = {
  ...lightThemeParams,
  ...commonThemeParams,
};
