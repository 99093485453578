import React, { useContext } from 'react';
import { useTheme, TableRow } from '@mui/material';
import { map } from 'lodash-es';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import TableWrapper from 'components/reviewResultsPage/common/cardLists/table/tableWrapper';
import TableHeaderWrapper from 'components/reviewResultsPage/common/cardLists/table/tableHeaderWrapper';
import TableCellWrapper from 'components/reviewResultsPage/common/cardLists/table/tableCellWrapper';
import TableBodyWrapper from 'components/reviewResultsPage/common/cardLists/table/tableBodyWrapper';
import { getScoreBorderStyles } from 'components/reviewResultsPage/common/cardLists/table/_helpers';
import IconBulb from 'icons/bulb';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { adaptSelfWordsData } from 'mobx/employeeForm/_adapters';
import { v4 as uuidv4 } from 'uuid';

export default observer(function Top10SelfWordsCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { selfWordsList } = employeeFormStore;

  if (!selfWordsList) {
    return null;
  }

  const headerLabels = ['СЛОВО', 'КОЛИЧЕСТВО'];
  const rowsData = adaptSelfWordsData(selfWordsList);

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        icon={<IconBulb />}
        mainText="ТОП 10 СЛОВ О СЕБЕ"
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableCellWrapper
              key={uuidv4()}
              text="№"
              cellSx={{
                borderTop: 2,
                borderTopColor: theme.palette.custom.darkGray,
              }}
            />

            {map(headerLabels, (label) => (
              <TableCellWrapper
                key={uuidv4()}
                text={label}
                cellSx={{
                  borderLeft: 2,
                  borderLeftColor: theme.palette.custom.darkGray,
                }}
              />
            ))}
          </TableHeaderWrapper>

          <TableBodyWrapper>
            {map(rowsData, (rowItem, index) => (
              <TableRow key={uuidv4()}>
                <TableCellWrapper
                  key={uuidv4()}
                  text={index + 1}
                  cellSx={{
                    borderTop: 2,
                    borderTopColor: theme.palette.custom.darkGray,
                  }}
                />

                {map(rowItem, (text, objectKey) => {
                  const scoreBorder =
                    objectKey === 'score' ? getScoreBorderStyles() : undefined;

                  const align = objectKey === 'mainText' ? 'left' : 'center';

                  return (
                    <TableCellWrapper
                      key={uuidv4()}
                      text={text}
                      align={align}
                      typographySx={scoreBorder}
                      cellSx={{
                        borderLeft: 2,
                        borderLeftColor: theme.palette.custom.darkGray,
                        borderTop: 2,
                        borderTopColor: theme.palette.custom.darkGray,
                      }}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableBodyWrapper>
        </TableWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
