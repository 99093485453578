import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { borderRadiusValues } from 'utils/uiConstants';
import { UiContext } from 'uiContext';

export default function InfoCardWrapper({
  children,
  isBorderEnabled,
}: {
  children: React.ReactNode;
  isBorderEnabled?: boolean;
}) {
  const theme = useTheme();

  const {
    spacings: { sp },
  } = useContext(UiContext);

  const borderStyles = isBorderEnabled
    ? {
        background:
          'linear-gradient(transparent, transparent) padding-box, linear-gradient(to bottom, #803DCB, #582394) border-box',
        border: '8px solid transparent',
      }
    : undefined;

  return (
    <Box
      sx={{
        borderRadius: borderRadiusValues.xxl,
        filter: theme.palette.custom.infoCardShadow,
        overflow: 'hidden',
        marginBottom: sp.s170,
        ...borderStyles,
      }}
    >
      {children}
    </Box>
  );
}
