import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconBulb({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconBulb', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_106_1106)">
        <path
          d="M19.9998 36.6663C19.2776 36.6663 18.6248 36.4927 18.0415 36.1455C17.4582 35.7983 16.9998 35.333 16.6665 34.7497C15.7498 34.7497 14.9651 34.4233 14.3123 33.7705C13.6596 33.1177 13.3332 32.333 13.3332 31.4163V25.4997C11.6943 24.4163 10.3818 22.9858 9.39567 21.208C8.40956 19.4302 7.9165 17.4997 7.9165 15.4163C7.9165 12.0552 9.09011 9.20106 11.4373 6.85384C13.7846 4.50662 16.6387 3.33301 19.9998 3.33301C23.3609 3.33301 26.2151 4.50662 28.5623 6.85384C30.9096 9.20106 32.0832 12.0552 32.0832 15.4163C32.0832 17.5552 31.5901 19.4997 30.604 21.2497C29.6179 22.9997 28.3054 24.4163 26.6665 25.4997V31.4163C26.6665 32.333 26.3401 33.1177 25.6873 33.7705C25.0346 34.4233 24.2498 34.7497 23.3332 34.7497C22.9998 35.333 22.5415 35.7983 21.9582 36.1455C21.3748 36.4927 20.7221 36.6663 19.9998 36.6663ZM16.6665 31.4163H23.3332V29.9163H16.6665V31.4163ZM16.6665 28.2497H23.3332V26.6663H16.6665V28.2497ZM16.3332 23.333H18.7498V18.833L15.0832 15.1663L16.8332 13.4163L19.9998 16.583L23.1665 13.4163L24.9165 15.1663L21.2498 18.833V23.333H23.6665C25.1665 22.6108 26.3887 21.5483 27.3332 20.1455C28.2776 18.7427 28.7498 17.1663 28.7498 15.4163C28.7498 12.9719 27.9026 10.9025 26.2082 9.20801C24.5137 7.51356 22.4443 6.66634 19.9998 6.66634C17.5554 6.66634 15.4859 7.51356 13.7915 9.20801C12.0971 10.9025 11.2498 12.9719 11.2498 15.4163C11.2498 17.1663 11.7221 18.7427 12.6665 20.1455C13.6109 21.5483 14.8332 22.6108 16.3332 23.333Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
