import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconCheckmark({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconCheckmark', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_106_1310)">
        <path
          d="M18.2082 23.4167L27.6665 14L25.2915 11.625L18.2082 18.7083L14.6665 15.1667L12.3332 17.5L18.2082 23.4167ZM1.6665 35V31.6667H38.3332V35H1.6665ZM6.6665 30C5.74984 30 4.96512 29.6736 4.31234 29.0208C3.65956 28.3681 3.33317 27.5833 3.33317 26.6667V8.33333C3.33317 7.41667 3.65956 6.63194 4.31234 5.97917C4.96512 5.32639 5.74984 5 6.6665 5H33.3332C34.2498 5 35.0346 5.32639 35.6873 5.97917C36.3401 6.63194 36.6665 7.41667 36.6665 8.33333V26.6667C36.6665 27.5833 36.3401 28.3681 35.6873 29.0208C35.0346 29.6736 34.2498 30 33.3332 30H6.6665ZM6.6665 26.6667H33.3332V8.33333H6.6665V26.6667Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
