import React from 'react';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';

export default function CombinedItemList({
  children,
}: {
  children: React.ReactNode;
}) {
  return <ListWrapper>{children}</ListWrapper>;
}
