import React, { useContext } from 'react';
import { map } from 'lodash-es';
import { ListItemText, Typography, useTheme } from '@mui/material';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconProjects from 'icons/projects';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { adaptProjectsData } from 'mobx/employeeForm/_adapters';

export default observer(function ProjectsCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { formProjects } = employeeFormStore;

  if (!formProjects) {
    return null;
  }

  const list = adaptProjectsData(formProjects);

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={<IconProjects />}
        mainText="проекты"
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <ListWrapper>
          {map(list, ({ mainText, header, subHeader }, index) => (
            <ListItemWrapper isSidePaddingEnabled key={index} index={index}>
              <ListItemText sx={{ color: theme.palette.custom.purple }}>
                <Typography variant="f22a" sx={{ fontWeight: 'bold' }}>
                  {header}
                </Typography>
              </ListItemText>

              <ListItemText>
                <Typography
                  variant="f22a"
                  sx={{ color: theme.palette.custom.white, fontWeight: 'bold' }}
                >
                  {subHeader}
                </Typography>
              </ListItemText>

              <ListItemText>
                <Typography
                  variant="f20a"
                  sx={{ color: theme.palette.custom.white }}
                >
                  {mainText}
                </Typography>
              </ListItemText>
            </ListItemWrapper>
          ))}
        </ListWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
