import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconBadge({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconBadge', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_50_45)">
        <path
          d="M6.66683 36.6667C5.75016 36.6667 4.96544 36.3403 4.31266 35.6875C3.65989 35.0347 3.3335 34.25 3.3335 33.3333V15C3.3335 14.0833 3.65989 13.2986 4.31266 12.6458C4.96544 11.9931 5.75016 11.6667 6.66683 11.6667H15.0002V6.66667C15.0002 5.75 15.3266 4.96528 15.9793 4.3125C16.6321 3.65972 17.4168 3.33333 18.3335 3.33333H21.6668C22.5835 3.33333 23.3682 3.65972 24.021 4.3125C24.6738 4.96528 25.0002 5.75 25.0002 6.66667V11.6667H33.3335C34.2502 11.6667 35.0349 11.9931 35.6877 12.6458C36.3404 13.2986 36.6668 14.0833 36.6668 15V33.3333C36.6668 34.25 36.3404 35.0347 35.6877 35.6875C35.0349 36.3403 34.2502 36.6667 33.3335 36.6667H6.66683ZM6.66683 33.3333H33.3335V15H25.0002C25.0002 15.9167 24.6738 16.7014 24.021 17.3542C23.3682 18.0069 22.5835 18.3333 21.6668 18.3333H18.3335C17.4168 18.3333 16.6321 18.0069 15.9793 17.3542C15.3266 16.7014 15.0002 15.9167 15.0002 15H6.66683V33.3333ZM10.0002 30H20.0002V29.25C20.0002 28.7778 19.8682 28.3403 19.6043 27.9375C19.3404 27.5347 18.9724 27.2222 18.5002 27C17.9446 26.75 17.3821 26.5625 16.8127 26.4375C16.2432 26.3125 15.6391 26.25 15.0002 26.25C14.3613 26.25 13.7571 26.3125 13.1877 26.4375C12.6182 26.5625 12.0557 26.75 11.5002 27C11.0279 27.2222 10.6599 27.5347 10.396 27.9375C10.1321 28.3403 10.0002 28.7778 10.0002 29.25V30ZM23.3335 27.5H30.0002V25H23.3335V27.5ZM15.0002 25C15.6946 25 16.2849 24.7569 16.771 24.2708C17.2571 23.7847 17.5002 23.1944 17.5002 22.5C17.5002 21.8056 17.2571 21.2153 16.771 20.7292C16.2849 20.2431 15.6946 20 15.0002 20C14.3057 20 13.7154 20.2431 13.2293 20.7292C12.7432 21.2153 12.5002 21.8056 12.5002 22.5C12.5002 23.1944 12.7432 23.7847 13.2293 24.2708C13.7154 24.7569 14.3057 25 15.0002 25ZM23.3335 22.5H30.0002V20H23.3335V22.5ZM18.3335 15H21.6668V6.66667H18.3335V15Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
