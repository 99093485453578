import { DashboardData } from 'mobx/storeTypes';
import { forEach, map, toPairs, isEmpty, filter } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
} from 'components/columnChart/columnChartTypes';
import {
  getEmptyColumnChartData,
  addDataLabelsToChartSeries,
  addPlotBands,
} from 'utils/dataAdapters/chartAdapters';
import { formatDate } from 'utils/dateFormat';
import { getDateColor } from 'utils/dataMappings';

type DepartmentPreparedItem = {
  color: string;
  sub_department_id: number;
  sub_department_name: string;
  name: string;
  start_date: string;
  y: number | null;
};

export const getDepartmentXAxisLabels = (
  data: DashboardData.DepartmentInfoList,
  filterBySubDepartmentName?: string
) => {
  const labels = map(data, (item) => item.sub_department_name);

  return filterBySubDepartmentName
    ? filter(labels, (label) => label === filterBySubDepartmentName)
    : labels;
};

export const getDepartmentDataAdapted = (
  data: DashboardData.DepartmentInfoList,
  filterBySubDepartmentName?: string
) => {
  if (isEmpty(data) || data.length === 0) {
    return getEmptyColumnChartData();
  }

  const prerapedMap: Record<string, DepartmentPreparedItem[]> = {};

  forEach(data, (item) => {
    const sub_department_id = item.sub_department_id;
    const sub_department_name = item.sub_department_name;
    const statistics = item.statistics;

    if (
      filterBySubDepartmentName &&
      sub_department_name !== filterBySubDepartmentName
    ) {
      return;
    }

    forEach(statistics, (statisticsItem) => {
      const start_date = formatDate(statisticsItem.start_date);

      const item = {
        y: statisticsItem.value,
        color: getDateColor(start_date),
        start_date,
        name: 'Средняя оценка',
        sub_department_id,
        sub_department_name,
      };

      if (start_date in prerapedMap) {
        prerapedMap[start_date].push(item);
      } else {
        prerapedMap[start_date] = [];
        prerapedMap[start_date].push(item);
      }
    });
  });

  const entriesFromMap = toPairs(prerapedMap);
  const preparedData: Array<ColumnChartSeriesType> = map(
    entriesFromMap,
    ([label, dataItems]) => {
      const newItem = {
        name: label,
        dataLabels: addDataLabelsToChartSeries(),
        data: dataItems,
      };

      return newItem;
    }
  );

  const xAxisCategories = getDepartmentXAxisLabels(
    data,
    filterBySubDepartmentName
  );

  return {
    series: preparedData,
    axisParams: {
      xAxisCategories,
      plotBands: addPlotBands(xAxisCategories),
      isScrollEnabled: preparedData[0].data.length > 8,
    },
  } as ColumnChartParams;
};
