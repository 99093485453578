import { SxProps, Theme } from '@mui/material';

// Можно хранить в константе, так как не зависят от размера экрана
export const borderRadiusValues = {
  XSmall: 0.25,
  small: 0.5,
  medium: 1,
  large: 1.5,
  xxl: 6,
};
export const DEFAULT_ELEVATION = 5;

export const zIndexes = {
  mapPage: {
    menu: {
      zIndex: 10,
    },
    currentInfo: {
      zIndex: 10,
    },
    mobileButtons: {
      zIndex: 0,
    },
    fieldsEditor: {
      zIndex: 2,
    },
  },
};

export const pxToRem = (val: number) => {
  return val / 16;
};

export const HIGHCHARTS_COLORS = [
  '#F67E6D',
  '#7CAED5',
  '#B1D760',
  '#BD7EBE',
  '#80E390',
  '#B2FFD6',
  '#DC55EB',
  '#A755EB',
  '#FB9330',
  '#181EDB',
  '#D40070',
  '#C50909',
];
export const CHART_OPACITY = 0.6;
export const getHighchartColor = (i: number) =>
  HIGHCHARTS_COLORS[i % HIGHCHARTS_COLORS.length];

export const sxMixins = {
  getScrollbarStyle: ({
    scroollableBg,
    scrollColor,
  }: {
    scroollableBg: string;
    scrollColor: string;
  }) => {
    return {
      '&::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },
      '&::-webkit-scrollbar-track': {
        border: `3px solid ${scroollableBg}`,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '20px',
        backgroundColor: `${scrollColor}`,
      },
      '&': {
        scrollbarWidth: 'thin !important',
        scrollbarColor: `${scrollColor} ${scroollableBg} !important`,
      },
    } as SxProps;
  },
  getMiniScroll: (theme: Theme) => {
    return {
      '&': {
        scrollbarWidth: 'thin',
        scrollbarColor: theme.palette.primary.main,
      },
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        border: 'none',
        backgroundColor: theme.palette.primary.main,
      },
    };
  },
};

export const sxPresets = {
  noselect: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  } as const,
};

export const proportionW = 16;
export const proportionH = 9;
export const getProportionHeightByWidth = (
  width: number,
  proportions: [number, number] = [proportionW, proportionH]
) => Math.round((width * proportions[1]) / proportions[0]);
