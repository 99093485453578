import React from 'react';
import { List } from '@mui/material';

export default function ListWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <List
      sx={{
        margin: 0,
        padding: 0,
      }}
    >
      {children}
    </List>
  );
}
