import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { UiContext } from 'uiContext';
import { useLocation } from 'react-router-dom';

export default function PageHeader() {
  const {
    spacings: { sp },
  } = useContext(UiContext);

  const location = useLocation();

  const headerText = location.pathname.includes('/employee_reviews_list/')
    ? 'Анкеты сотрудника'
    : 'Performance Review';

  return (
    <Box
      sx={{
        paddingLeft: sp.s60,
        paddingRight: sp.s60,
        paddingTop: sp.s100,
        paddingBottom: sp.s100,
      }}
    >
      <Typography variant="f30">{headerText}</Typography>
    </Box>
  );
}
