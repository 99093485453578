import { departmentsAPI } from 'api/departments';
import { ColumnChartParams } from 'components/columnChart/columnChartTypes';
import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { DashboardData } from 'mobx/storeTypes';
import { getSubDepartmentDataAdapted } from './_adapters';
import { forEach } from 'lodash-es';
export class SubDepartmentStore {
  public rootStore: RootStore;
  public currentSubDepartmentId: number | null = null;
  public subDepartmentInfo: DashboardData.SubDepartmentInfoList | null = null;
  public adaptedSubDepartmentData: ColumnChartParams | null = null;
  public currentSubDepartmentName: string | null = null;
  public isDataFetching = false;
  public reviewPeriodsIds: number[] | null = null;

  private _handlePlotBandsClick:
    | null
    | ((employe_id: number, employe_name: string) => void) = null;
  public set handlePlotBandsClick(
    val: (employe_id: number, employe_name: string) => void
  ) {
    this._handlePlotBandsClick = val;
  }

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public setCurrentSubDepartmentId(id: number | null) {
    this.currentSubDepartmentId = id;
  }

  public async fetchSubDepartmentData(id: number, subDepartmentName: string) {
    this.setCurrentSubDepartmentId(id);
    this.setCurrentSubDepartmentName(subDepartmentName);

    this.rootStore.periodFilter.resetAllPeriodStatusesToActive();

    try {
      this._setIsDataFetching(true);
      const res = await departmentsAPI.getSubDepartmentStatisticsById(id);

      this.setSubDepartmentInfo(res);
      this._adaptData();
      this._setIsDataFetching(false);
    } catch (e) {
      console.error(e);
    }
  }

  private _setIsDataFetching(val: boolean) {
    this.isDataFetching = val;
  }

  public setCurrentSubDepartmentName(value: string | null) {
    this.currentSubDepartmentName = value;
  }

  public setSubDepartmentInfo(data: DashboardData.SubDepartmentInfoList) {
    this.subDepartmentInfo = data;
  }

  private _adaptData() {
    if (this.subDepartmentInfo) {
      const data = getSubDepartmentDataAdapted(
        this.subDepartmentInfo,
        this._handlePlotBandsClick
      );
      this._setAdaptedData(data);
    }
  }

  private _setAdaptedData(data: ColumnChartParams) {
    this.adaptedSubDepartmentData = data;
  }

  public filterChartSeriesByPeriod() {
    this._adaptData();
    const newSeries = this.adaptedSubDepartmentData?.series;

    const enabledSeries = this.rootStore.periodFilter.enabledSeries;
    const filteredSeries =
      newSeries &&
      newSeries.filter((item) => {
        return enabledSeries.includes(item.name as string);
      });

    const newAdaptedData = {
      series: filteredSeries,
      axisParams: this.adaptedSubDepartmentData?.axisParams,
    } as ColumnChartParams;

    this._setAdaptedData(newAdaptedData);
  }

  public getReviewPeriodIds() {
    const allDepartmentsStatistics =
      this.rootStore.allDepartmentsStore.allDepartmentsStatistics;
    const currentDepartmentId =
      this.rootStore.departmentStore.currentDepartmentId;

    if (allDepartmentsStatistics && currentDepartmentId) {
      const departmentStatistics = allDepartmentsStatistics.find(
        (item) => item.department_id === currentDepartmentId
      );

      const periodIds: number[] = [];
      forEach(departmentStatistics?.statistics, (statItem) => {
        const reviewPeriodId = statItem.review_period_id;
        periodIds.push(reviewPeriodId);
      });

      this.reviewPeriodsIds = periodIds;
    }
  }

  public clearSubdepartmentInfo() {
    this.currentSubDepartmentId = null;
    this.currentSubDepartmentName = null;
  }
}
