import React, { useContext } from 'react';
import { map, isNull } from 'lodash-es';
import { Box, ListItemText, Typography, useTheme } from '@mui/material';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconStar from 'icons/star';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { adaptAvgReviewGradesData } from 'mobx/employeeForm/_adapters';

export default observer(function AvgReviewGradeCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { marksAnalysis } = employeeFormStore;

  if (!marksAnalysis) {
    return null;
  }

  const list = adaptAvgReviewGradesData(marksAnalysis);
  const avgScore = isNull(marksAnalysis.total_average_mark)
    ? '-'
    : marksAnalysis.total_average_mark;

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        icon={<IconStar />}
        mainText="Средняя оценка по анкете"
        lowerText="среднее арифметическое из оценок ниже"
        bgColor={theme.palette.custom.infoCardHeaderColor}
        score={avgScore}
      />

      <InfoCardContentWrapper>
        <ListWrapper>
          {map(list, ({ mainText, upperText, score }, index) => (
            <ListItemWrapper isSidePaddingEnabled key={index} index={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: 1,
                }}
              >
                <ListItemText sx={{ color: theme.palette.custom.purple }}>
                  <Typography variant="f24a">{upperText}</Typography>
                </ListItemText>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: 1,
                }}
              >
                <ListItemText sx={{ width: 1 }}>
                  <Typography
                    variant="f38a"
                    sx={{ color: theme.palette.custom.white }}
                  >
                    {mainText}
                  </Typography>
                </ListItemText>

                <ListItemText sx={{ width: 1 }}>
                  <Typography
                    variant="f50a"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      textAlign: 'end',
                      backgroundImage: theme.palette.custom.infoCardHeaderColor,
                      backgroundClip: 'text',
                      textFillColor: 'transparent',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {score}
                  </Typography>
                </ListItemText>
              </Box>
            </ListItemWrapper>
          ))}
        </ListWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
