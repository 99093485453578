import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ColumnChart from 'components/columnChart/columnChart';
import { MobxContext } from 'mobx/mobxContext';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import RegularTooltip from 'components/tooltips/regularTooltip';
import { SpecialDepartments } from 'mobx/storeTypes';

export default observer(function DepartmentChart() {
  const {
    mobxStore: {
      departmentStore,
      subDepartmentStore,
      employeeStore,
      employeeFormStore,
    },
  } = useContext(MobxContext);

  const { adaptedDepartmentData } = departmentStore;

  const navigate = useNavigate();
  const location = useLocation();

  const formatterFn: Highcharts.TooltipFormatterCallbackFunction = function () {
    const datePeriod = this.series.name;

    return ReactDOMServer.renderToString(<RegularTooltip date={datePeriod} />);
  };

  const handleDepartmentPath = function (e: Highcharts.SeriesClickEventObject) {
    if (location.pathname.includes('/department/')) {
      const options = e.point.options as any;
      const subDepartmentId = options.sub_department_id;
      const subDepartmentName = options.sub_department_name;

      // Костыль
      subDepartmentStore.handlePlotBandsClick = async (
        employe_id,
        employe_name
      ) => {
        await employeeFormStore.fetchReviewPeriods(employe_id);
        await employeeStore.fetchEmployeeData(employe_id, employe_name);
        employeeStore.setIsReviewsListCurrentRoute(true);
        navigate(`/employee_reviews_list/${employe_id}`);
      };

      subDepartmentStore.fetchSubDepartmentData(
        subDepartmentId,
        subDepartmentName
      );
      navigate(`/sub_department/${subDepartmentStore.currentSubDepartmentId}`);
    }
  };

  useEffect(() => {
    if (departmentStore.currentDepartmentId === SpecialDepartments.DEPUTIES) {
      // Костыль
      subDepartmentStore.fetchSubDepartmentData(-1, 'ЗАМы');
      navigate(`/sub_department/${subDepartmentStore.currentSubDepartmentId}`);
    }
  }, [
    departmentStore,
    subDepartmentStore,
    departmentStore.currentDepartmentId,
    navigate,
  ]);

  if (
    !adaptedDepartmentData?.series ||
    departmentStore.currentDepartmentId === SpecialDepartments.DEPUTIES
  ) {
    return null;
  }

  return (
    <Box>
      <ColumnChart
        chartData={adaptedDepartmentData}
        plotOptionsArgs={{
          handleClick: handleDepartmentPath,
          cursor: 'pointer',
        }}
        tooltipOptionsArgs={{
          formatter: formatterFn,
          outside: false,
          padding: 8,
        }}
      />
    </Box>
  );
});
