import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconBreadCrumbSeparator({
  onClick,
  isAutoscallable = true,
  sizeKey = '20x20',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconBreadCrumbSeparator', {
        'icon-disabled': !onClick,
      })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_409_108)">
        <path
          d="M3.71666 0.5C3.59792 0.5 3.47641 0.546949 3.38529 0.638072C3.20305 0.820318 3.20305 1.11854 3.38529 1.30079L9.62317 7.54143L3.47642 13.6882C3.29417 13.8704 3.29417 14.1687 3.47642 14.3509C3.65866 14.5332 3.95687 14.5332 4.13912 14.3509L10.6172 7.8728C10.7995 7.69056 10.7995 7.39232 10.6172 7.21007L4.048 0.638086C3.95687 0.546963 3.8354 0.500027 3.71664 0.500027L3.71666 0.5Z"
          fill="#615A69"
        />
      </g>
      <defs>
        <clipPath id="clip0_409_108">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="matrix(-1 0 0 1 14 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
