import React, { useContext } from 'react';
import {
  Box,
  ListItemText,
  Typography,
  TableRow,
  useTheme,
} from '@mui/material';
import { map } from 'lodash-es';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconChat from 'icons/chat';
import CombinedItemList from 'components/reviewResultsPage/common/cardLists/combinedItemList/combinedItemList';
import TableWrapper from 'components/reviewResultsPage/common/cardLists/table/tableWrapper';
import TableHeaderWrapper from 'components/reviewResultsPage/common/cardLists/table/tableHeaderWrapper';
import TableCellWrapper from 'components/reviewResultsPage/common/cardLists/table/tableCellWrapper';
import TableBodyWrapper from 'components/reviewResultsPage/common/cardLists/table/tableBodyWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import {
  adaptLeadAdvice,
  adaptLeadReviesTableItemList,
} from 'mobx/employeeForm/_adapters';
import { v4 as uuidv4 } from 'uuid';
import { cantRateText } from 'utils/helpers';

export default observer(function EmployeeReviewsCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { advicesList, employeeData, marksAnalysis, projectsInfo } =
    employeeFormStore;

  if (!advicesList || !employeeData || !marksAnalysis || !projectsInfo) {
    return null;
  }

  const leadAdvice = adaptLeadAdvice(advicesList);
  const rowsData = adaptLeadReviesTableItemList(projectsInfo);

  const tableHeaderLabels = ['НАЗВАНИЕ ПРОЕКТА', 'ОЦЕНКА', 'КОММЕНТАРИЙ'];

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={<IconChat />}
        mainText="ОТЗЫВЫ О СОТРУДНИКЕ"
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <CombinedItemList>
          <ListItemWrapper isSidePaddingEnabled index={0}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText
                sx={{ color: theme.palette.custom.fontColorNotActive }}
              >
                <Typography variant="f24a">Руководитель</Typography>
              </ListItemText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText>
                <Typography
                  variant="f38a"
                  sx={{ color: theme.palette.custom.white }}
                >
                  {employeeData.lead}
                </Typography>
              </ListItemText>
            </Box>
          </ListItemWrapper>

          <ListItemWrapper isSidePaddingEnabled index={1}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText
                sx={{ color: theme.palette.custom.fontColorNotActive }}
              >
                <Typography variant="f24a">Оценка</Typography>
              </ListItemText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText sx={{ width: 1 }}>
                <Typography variant="f38a">
                  Средняя оценка за проекты от руководителя
                </Typography>
              </ListItemText>

              <ListItemText sx={{ width: 1 }}>
                <Typography
                  variant="f50a"
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    textAlign: 'end',
                    backgroundImage: theme.palette.custom.infoCardHeaderColor,
                    backgroundClip: 'text',
                    textFillColor: 'transparent',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {marksAnalysis.lead_average_mark}
                </Typography>
              </ListItemText>
            </Box>
          </ListItemWrapper>

          <TableWrapper
            sx={{
              borderTop: 2,
              borderTopColor: theme.palette.custom.darkGray,
              borderBottom: 2,
              borderBottomColor: theme.palette.custom.darkGray,
            }}
          >
            <TableHeaderWrapper>
              <TableCellWrapper key={uuidv4()} text="№" />

              {map(tableHeaderLabels, (label) => (
                <TableCellWrapper
                  key={uuidv4()}
                  text={label}
                  cellSx={{
                    borderLeft: 2,
                    borderLeftColor: theme.palette.custom.darkGray,
                    borderTop: 2,
                    borderTopColor: theme.palette.custom.darkGray,
                  }}
                />
              ))}
            </TableHeaderWrapper>

            <TableBodyWrapper>
              {map(rowsData, (rowItem, index) => (
                <TableRow
                  key={uuidv4()}
                  sx={{
                    borderTop: 2,
                    borderTopColor: theme.palette.custom.darkGray,
                  }}
                >
                  <TableCellWrapper key={uuidv4()} text={index + 1} />

                  {map(rowItem, (text, objectKey) => {
                    if (objectKey === 'score' && text === -1) {
                      text = cantRateText;
                    }

                    const align =
                      objectKey === 'projectName' || objectKey === 'comment'
                        ? 'left'
                        : 'center';
                    const scoreColor =
                      objectKey === 'score'
                        ? theme.palette.custom.purpleDark
                        : undefined;

                    return (
                      <TableCellWrapper
                        key={uuidv4()}
                        text={text}
                        align={align}
                        cellSx={{
                          borderLeft: 2,
                          borderLeftColor: theme.palette.custom.darkGray,
                        }}
                        typographySx={{
                          color: scoreColor,
                        }}
                      />
                    );
                  })}
                </TableRow>
              ))}
            </TableBodyWrapper>
          </TableWrapper>

          <ListItemWrapper
            isSidePaddingEnabled
            index={2}
            bgColor={theme.palette.customBackgrounds.darkBlack}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText
                sx={{ color: theme.palette.custom.fontColorNotActive }}
              >
                <Typography variant="f24a">{leadAdvice.upperText}</Typography>
              </ListItemText>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: 1,
              }}
            >
              <ListItemText>
                <Typography
                  variant="f38a"
                  sx={{ color: theme.palette.custom.white }}
                >
                  {leadAdvice.mainText}
                </Typography>
              </ListItemText>
            </Box>
          </ListItemWrapper>
        </CombinedItemList>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
