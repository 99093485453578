import { makeAutoObservable } from 'mobx';
import { RootStore } from 'mobx/rootStore';
import { adaptDataToSeriesMap, adaptDataToEnabledSeries } from './_helpers';
import { departmentsAPI } from 'api/departments';
import { filter } from 'lodash-es';

export type SeriesMap = {
  [key: string]: { seriesName: string; color: string };
};

export class PeriodFilter {
  public rootStore: RootStore;
  public seriesMap: SeriesMap | null = null;
  public enabledSeries: string[] = [];
  public isClickedOnce = false;

  constructor({ rootStore }: { rootStore: RootStore }) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  public init() {
    this._initFilters();
  }

  private async _initFilters() {
    try {
      const res = await departmentsAPI.getReviewFilterPeriods();

      const seriesMap = adaptDataToSeriesMap(res);
      const enabledSeries = adaptDataToEnabledSeries(res);

      this._setSeriesMap(seriesMap);
      this._setEnabledSeries(enabledSeries);
    } catch (e) {
      console.error(e);
    }
  }

  private _setIsClickedOnce(val: boolean) {
    this.isClickedOnce = val;
  }

  private _setSeriesMap(value: SeriesMap | null) {
    this.seriesMap = value;
  }

  private _setEnabledSeries(value: string[]) {
    this.enabledSeries = value;
  }

  public toggleById(id: string) {
    this._setIsClickedOnce(true);

    if (!this.enabledSeries?.includes(id)) {
      this.enabledSeries = [...this.enabledSeries, id];
    } else {
      const enabledSeries = filter(
        this.enabledSeries,
        (seriesName) => seriesName !== id
      );
      this.enabledSeries = enabledSeries;
    }
  }

  public resetAllPeriodStatusesToActive() {
    const allDepartmentsData =
      this.rootStore.allDepartmentsStore.allDepartmentsStatistics;
    if (allDepartmentsData) {
      const enabledSeries = adaptDataToEnabledSeries(
        allDepartmentsData[0].statistics
      );

      if (enabledSeries.length === this.enabledSeries.length) {
        return;
      }

      this.enabledSeries = enabledSeries;
    }
  }
}
