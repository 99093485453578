import React, { useContext } from 'react';
import { ButtonBase, ButtonBaseProps, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';
import { getDefaultFontSize } from 'hooks/useGetTypographyFonts';

type ButtonComponentProps = { enabled: boolean } & ButtonBaseProps;

export default function ButtonComponent({
  children,
  sx,
  onClick,
  enabled,
  color,
}: ButtonComponentProps) {
  const theme = useTheme();
  const {
    spacings: { sp },
  } = useContext(UiContext);

  return (
    <ButtonBase
      sx={{
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'backgroundColor': color,
        'padding': sp.s20,
        'paddingLeft': sp.s40,
        'paddingRight': sp.s40,
        'borderRadius': '100px',
        'maxHeight': '31px',
        'fontSize': getDefaultFontSize('f16'),
        'color': enabled
          ? theme.palette.custom.fontColor
          : theme.palette.custom.fontColorNotActive,
        ...sx,

        '&:hover': {
          backgroundColor: {
            filter: 'brightness(95%)',
          },
        },
      }}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
}
