import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconProjects({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconProjects', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_106_708)">
        <path
          d="M6.66683 35C5.75016 35 4.96544 34.6736 4.31266 34.0208C3.65989 33.3681 3.3335 32.5833 3.3335 31.6667V8.33333C3.3335 7.41667 3.65989 6.63194 4.31266 5.97917C4.96544 5.32639 5.75016 5 6.66683 5H33.3335C34.2502 5 35.0349 5.32639 35.6877 5.97917C36.3404 6.63194 36.6668 7.41667 36.6668 8.33333V31.6667C36.6668 32.5833 36.3404 33.3681 35.6877 34.0208C35.0349 34.6736 34.2502 35 33.3335 35H6.66683ZM6.66683 31.6667H33.3335V8.33333H6.66683V31.6667ZM8.3335 28.3333H16.6668V25H8.3335V28.3333ZM24.2502 25L32.5002 16.75L30.1252 14.375L24.2502 20.2917L21.8752 17.9167L19.5418 20.2917L24.2502 25ZM8.3335 21.6667H16.6668V18.3333H8.3335V21.6667ZM8.3335 15H16.6668V11.6667H8.3335V15Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
