import { ThemeProvider } from '@mui/material';
import { useCurrentBreakpoint } from 'hooks/useCurrentBreakpoint';
import { useCurrentTheme } from 'hooks/useCurrentTheme';
import { useGetSpacing } from 'hooks/useGetSpacing';
import { useTypographyFonts } from 'hooks/useGetTypographyFonts';
import { useIsMobile } from 'hooks/useIsMobile';
import React from 'react';
import { UiContext } from 'uiContext';

function SizesComponent({ children }: { children: React.ReactNode }) {
  const currentBp = useCurrentBreakpoint();
  const spacingPreset = useGetSpacing({ currentBp });
  const { getFontSize } = useTypographyFonts({ currentBp });
  const isMobile = useIsMobile(currentBp);

  return (
    <UiContext.Provider
      value={{
        spacings: spacingPreset,
        getFontSize,
        isMobile,
      }}
    >
      {children}
    </UiContext.Provider>
  );
}

export default function UiWrapper({ children }: { children: React.ReactNode }) {
  const currentTheme = useCurrentTheme();

  return (
    <ThemeProvider theme={currentTheme}>
      <SizesComponent>{children}</SizesComponent>
    </ThemeProvider>
  );
}
