import dayjs from 'dayjs';
import { monthMappings, monthToSeasonMappings } from './dataMappings';

export const formatDate = (date: string) => {
  const month = dayjs(date).format('MMMM');
  const year = dayjs(date).format('YYYY');

  return `${monthMappings[month]} ${year}`;
};

export const formatDateToPeriod = (date: string) => {
  const month = dayjs(date).format('MMMM');
  const year = dayjs(date).format('YYYY');

  return `${monthToSeasonMappings[month]} ${year}`;
};
