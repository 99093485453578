import React from 'react';
import { Box, Typography } from '@mui/material';

export default function EmptyChart() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 1,
        height: 200,
      }}
    >
      <Typography variant="f22">Нет данных</Typography>
    </Box>
  );
}
