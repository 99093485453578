import React from 'react';
import { MobxContext } from './mobxContext';
import mobxStore from './rootStore';

export default function MobxContextWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <MobxContext.Provider value={{ mobxStore: mobxStore }}>
      {children}
    </MobxContext.Provider>
  );
}
