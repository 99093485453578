import React from 'react';
import { Box, SxProps } from '@mui/material';

export default function ChartStyleWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <Box
      sx={{
        'position': 'relative',
        'display': 'block',
        '& .highcharts-credits': {
          display: 'none',
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
