import React from 'react';
import { Snackbar, useTheme } from '@mui/material';

export default function Notification({
  message,
  open,
}: {
  message: string;
  open: boolean;
}) {
  const theme = useTheme();

  return (
    <Snackbar
      open={open}
      message={message}
      sx={{
        'width': 'max-content',
        '& .MuiPaper-root': {
          backgroundColor: theme.palette.custom.darkGray,
          color: theme.palette.custom.white,
        },
      }}
    />
  );
}
