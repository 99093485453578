import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconStar({
  onClick,
  isAutoscallable = true,
  sizeKey = '38x38',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconStar', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_55_5920)">
        <path
          d="M14.7502 29.7083L20.0002 26.5417L25.2502 29.75L23.8752 23.75L28.5002 19.75L22.4168 19.2083L20.0002 13.5417L17.5835 19.1667L11.5002 19.7083L16.1252 23.75L14.7502 29.7083ZM9.7085 36.6667L12.4168 24.9583L3.3335 17.0833L15.3335 16.0417L20.0002 5L24.6668 16.0417L36.6668 17.0833L27.5835 24.9583L30.2918 36.6667L20.0002 30.4583L9.7085 36.6667Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
