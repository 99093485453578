/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Breakpoint,
  TypographyPropsVariantOverrides,
  useTheme,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useCallback } from 'react';

export const useTypographyFonts = ({
  currentBp,
}: {
  currentBp: Breakpoint;
}) => {
  const theme = useTheme();

  const getFontSize = useCallback(
    (variant: keyof TypographyPropsVariantOverrides | Variant) => {
      const mediaString = theme.breakpoints.up(currentBp);
      // @ts-ignore
      return theme.typography[variant][mediaString].fontSize as string;
    },
    [theme, currentBp]
  );

  return {
    getFontSize: getFontSize,
  };
};

export const getDefaultFontSize = (
  variant: keyof TypographyPropsVariantOverrides | Variant
) => {
  return '1rem';
};
