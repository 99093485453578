import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useCurrentBreakpoint = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('mobile'));
  const tablet = useMediaQuery(theme.breakpoints.up('tablet'));
  const laptop = useMediaQuery(theme.breakpoints.up('laptop'));
  const desktop_small = useMediaQuery(theme.breakpoints.up('desktop_small'));
  const desktop_middle = useMediaQuery(theme.breakpoints.up('desktop_middle'));
  const desktop_large = useMediaQuery(theme.breakpoints.up('desktop_large'));

  switch (true) {
    case desktop_large:
      return 'desktop_large' as Breakpoint;
    case desktop_middle:
      return 'desktop_middle' as Breakpoint;
    case desktop_small:
      return 'desktop_small' as Breakpoint;
    case laptop:
      return 'laptop' as Breakpoint;
    case tablet:
      return 'tablet' as Breakpoint;
    case mobile:
      return 'mobile' as Breakpoint;
    default:
      return 'mobile' as Breakpoint;
  }
};
