import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import IconPaper from 'icons/paper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { useNavigate } from 'react-router-dom';

export default observer(function ArchiveListItem({
  periodDate,
  periodId,
  periodFileId,
}: {
  periodDate: string;
  periodId: number;
  periodFileId: string;
}) {
  const {
    mobxStore: { employeeFormStore, employeeStore },
  } = useContext(MobxContext);

  const { currentEmployeeId } = employeeStore;

  const navigate = useNavigate();

  const handleEmployeeFormClick = async () => {
    if (currentEmployeeId) {
      await employeeFormStore.fetchTotalData(currentEmployeeId, periodId);
      navigate('/employee_review');
    }
  };

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      onClick={handleEmployeeFormClick}
    >
      <Box>
        <IconPaper />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>PR</Typography>
        <Typography>{periodDate}</Typography>
      </Box>
    </Box>
  );
});
