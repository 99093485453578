import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ColumnChart from 'components/columnChart/columnChart';
import { MobxContext } from 'mobx/mobxContext';
import { Box, useTheme } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import EmployeeTooltip from 'components/tooltips/employeeTooltip';

export default observer(function EmployeeChart() {
  const {
    mobxStore: { employeeStore, employeeFormStore },
  } = useContext(MobxContext);

  const theme = useTheme();

  const { adaptedEmployeeData } = employeeStore;
  const { currentEmployeeId } = employeeStore;

  useEffect(() => {
    const fetchReviewPeriods = async () => {
      if (currentEmployeeId) {
        await employeeFormStore.fetchReviewPeriods(currentEmployeeId);
      }
    };

    fetchReviewPeriods();
  }, [currentEmployeeId, employeeFormStore]);

  const formatterFn: Highcharts.TooltipFormatterCallbackFunction = function () {
    const options = this.point.options as any;

    return ReactDOMServer.renderToString(
      <EmployeeTooltip
        theme={theme}
        by_lead={options.by_lead}
        by_coworkers={options.by_coworkers}
        by_subordinates={options.by_subordinates}
        date={options.start_date}
        value={options.y}
      />
    );
  };

  if (!adaptedEmployeeData?.series) {
    return null;
  }

  return (
    <Box>
      <ColumnChart
        chartData={adaptedEmployeeData}
        tooltipOptionsArgs={{
          formatter: formatterFn,
          outside: true,
          padding: 16,
        }}
        plotOptionsArgs={{}}
      />
    </Box>
  );
});
