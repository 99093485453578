import React, { useContext } from 'react';
import {
  Breadcrumbs as MUIBreadCrumbs,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { useNavigate } from 'react-router-dom';
import IconBreadCrumbSeparator from 'icons/breadCrumbSeparator';
import {
  checkIfRoleAllowRootNavigation,
  checkIfRoleAllowDepartmentNavigation,
} from 'utils/helpers';

export default observer(function Breadcrumbs() {
  const {
    mobxStore: {
      periodFilter,
      departmentStore,
      subDepartmentStore,
      employeeStore,
      authInfoStore,
    },
  } = useContext(MobxContext);

  const { authInfo } = authInfoStore;

  const theme = useTheme();

  const navigate = useNavigate();

  const { currentDepartmentId, currentDepartmentName } = departmentStore;
  const { currentSubDepartmentId, currentSubDepartmentName } =
    subDepartmentStore;
  const { currentEmployeeId, currentEmployeeName, isReviewsListCurrentRoute } =
    employeeStore;

  const handleRootNavigate = () => {
    if (authInfo && !checkIfRoleAllowRootNavigation(authInfo.role)) {
      authInfoStore.setIsNotificationOpened(true);
      authInfoStore.setTimeoutId(null);
      return;
    }

    periodFilter.resetAllPeriodStatusesToActive();
    departmentStore.clearDepartmentInfo();
    subDepartmentStore.clearSubdepartmentInfo();
    employeeStore.clearEmployeeInfo();
    navigate('/');
  };

  const handleDepartmentNavigate = () => {
    if (authInfo && !checkIfRoleAllowDepartmentNavigation(authInfo.role)) {
      authInfoStore.setIsNotificationOpened(true);
      authInfoStore.setTimeoutId(null);
      return;
    }

    periodFilter.resetAllPeriodStatusesToActive();
    subDepartmentStore.clearSubdepartmentInfo();
    employeeStore.clearEmployeeInfo();
    navigate(`/department/${currentDepartmentId}`);
  };

  const handleSubDepartmentNavigate = () => {
    if (authInfo && !checkIfRoleAllowDepartmentNavigation(authInfo.role)) {
      authInfoStore.setIsNotificationOpened(true);
      authInfoStore.setTimeoutId(null);
      return;
    }

    periodFilter.resetAllPeriodStatusesToActive();
    employeeStore.clearEmployeeInfo();
    navigate(`/sub_department/${currentSubDepartmentId}`);
  };

  const handleEmployeeNavigate = () => {
    employeeStore.setIsReviewsListCurrentRoute(false);
    navigate(`/employee/${currentEmployeeId}`);
  };

  return (
    <MUIBreadCrumbs separator={<IconBreadCrumbSeparator />}>
      <Typography
        variant="f16"
        onClick={handleRootNavigate}
        sx={{
          cursor: 'pointer',
          color:
            !currentDepartmentId && !currentSubDepartmentId
              ? theme.palette.custom.fontColor
              : theme.palette.custom.fontColorNotActive,
        }}
      >
        Инновационный центр
      </Typography>

      {currentDepartmentId && currentDepartmentName && (
        <Typography
          variant="f16"
          onClick={handleDepartmentNavigate}
          sx={{
            cursor: 'pointer',
            color:
              currentDepartmentId && !currentSubDepartmentId
                ? theme.palette.custom.fontColor
                : theme.palette.custom.fontColorNotActive,
          }}
        >
          {currentDepartmentName}
        </Typography>
      )}

      {currentSubDepartmentId && currentSubDepartmentName && (
        <Typography
          variant="f16"
          onClick={handleSubDepartmentNavigate}
          sx={{
            cursor: 'pointer',
            color:
              currentDepartmentId &&
              currentSubDepartmentId &&
              !currentEmployeeId
                ? theme.palette.custom.fontColor
                : theme.palette.custom.fontColorNotActive,
          }}
        >
          {currentSubDepartmentName}
        </Typography>
      )}

      {currentEmployeeId && currentEmployeeName && (
        <Typography
          variant="f16"
          sx={{
            cursor: 'pointer',
            color:
              currentDepartmentId &&
              currentSubDepartmentId &&
              currentEmployeeId &&
              !isReviewsListCurrentRoute
                ? theme.palette.custom.fontColor
                : theme.palette.custom.fontColorNotActive,
          }}
          onClick={handleEmployeeNavigate}
        >
          {currentEmployeeName}
        </Typography>
      )}

      {currentEmployeeId &&
        currentEmployeeName &&
        isReviewsListCurrentRoute && (
          <Typography
            variant="f16"
            sx={{
              color:
                currentDepartmentId &&
                currentSubDepartmentId &&
                currentEmployeeId &&
                isReviewsListCurrentRoute
                  ? theme.palette.custom.fontColor
                  : theme.palette.custom.fontColorNotActive,
            }}
          >
            Анкета
          </Typography>
        )}
    </MUIBreadCrumbs>
  );
});
