import { forEach } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
} from 'components/columnChart/columnChartTypes';
import { XAxisPlotBandsOptions } from 'highcharts';

const PLOT_BAND_OFFSET = 0.3;

export const getEmptyColumnChartData = () =>
  ({
    series: [] as ColumnChartSeriesType[],
  }) as ColumnChartParams;

export const addPlotBands = (
  itemsList: string[],
  onClick?: (name: string) => void
) => {
  const plotBands = [] as XAxisPlotBandsOptions[];

  forEach(itemsList, (name, index) => {
    const newItem = {
      color: '#393241',
      from: index - PLOT_BAND_OFFSET,
      to: index + PLOT_BAND_OFFSET,
      events: onClick
        ? {
            click: function () {
              onClick(name);
            },
            mouseover: function () {
              this.svgElem.attr('fill', '#3f4054');
              this.svgElem.attr('cursor', 'pointer');
            },
            mouseout: function () {
              this.svgElem.attr('fill', '#393241');
            },
          }
        : {},
    } as XAxisPlotBandsOptions;
    plotBands.push(newItem);
  });

  return plotBands;
};

export const addDataLabelsToChartSeries = () => ({
  enabled: true,
  style: { color: '#f5f5f5' },
});
