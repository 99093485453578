import React, { useContext } from 'react';
import { map } from 'lodash-es';
import { Box, ListItemText, Typography, useTheme } from '@mui/material';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconBadge from 'icons/badge';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { adaptEmployeeData } from 'mobx/employeeForm/_adapters';

export default observer(function CommonCard() {
  const theme = useTheme();

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { employeeData } = employeeFormStore;

  if (!employeeData) {
    return null;
  }

  const list2 = adaptEmployeeData(employeeData);

  return (
    <InfoCardWrapper isBorderEnabled>
      <InfoCardHeader
        uppercase
        icon={<IconBadge />}
        mainText={employeeData.fullname}
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <ListWrapper>
          {map(list2, ({ mainText, upperText }, index) => (
            <ListItemWrapper isSidePaddingEnabled key={index} index={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: 1,
                }}
              >
                <ListItemText sx={{ color: theme.palette.custom.purple }}>
                  <Typography variant="f24">{upperText}</Typography>
                </ListItemText>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: 1,
                }}
              >
                <ListItemText>
                  <Typography
                    variant="f38"
                    sx={{ color: theme.palette.custom.white }}
                  >
                    {mainText}
                  </Typography>
                </ListItemText>
              </Box>
            </ListItemWrapper>
          ))}
        </ListWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
