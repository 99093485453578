import React, { useContext, useEffect } from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';
import { map } from 'lodash-es';
import FilterButton from 'components/filterButton/filterButton';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import { useLocation } from 'react-router-dom';

export default observer(function PeriodFilter() {
  const theme = useTheme();

  const { mobxStore } = useContext(MobxContext);

  const {
    periodFilter,
    allDepartmentsStore,
    departmentStore,
    subDepartmentStore,
    employeeStore,
  } = mobxStore;

  const { seriesMap, enabledSeries, isClickedOnce } = periodFilter;

  const {
    isMobile,
    spacings: { sp },
  } = useContext(UiContext);

  const location = useLocation();

  useEffect(() => {
    if (isClickedOnce) {
      if (location.pathname === '/') {
        allDepartmentsStore.filterChartSeriesByPeriod();
      }

      if (location.pathname.includes('/department/')) {
        departmentStore.filterChartSeriesByPeriod();
      }

      if (location.pathname.includes('/sub_department/')) {
        subDepartmentStore.filterChartSeriesByPeriod();
      }

      if (location.pathname.includes('/employee/')) {
        employeeStore.filterChartSeriesByPeriod();
      }
    }
  }, [
    location.pathname,
    isClickedOnce,
    enabledSeries,
    allDepartmentsStore,
    departmentStore,
    subDepartmentStore,
    employeeStore,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'start' : 'center',
        justifyContent: 'space-between',
        paddingLeft: sp.s60,
        paddingRight: sp.s60,
        minHeight: '60px',
      }}
    >
      <Box>
        <Breadcrumbs />
      </Box>
      {!location.pathname.includes('/employee_reviews_list/') && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: sp.s20,
          }}
        >
          <Typography
            variant="f16"
            sx={{ color: theme.palette.custom.fontColorNotActive }}
          >
            Период:
          </Typography>
          <Paper
            sx={{
              backgroundColor: theme.palette.custom.paperBgColor,
              display: 'flex',
              gap: sp.s30,
              alignItems: 'center',
              padding: sp.s40,
              borderRadius: '100px',
            }}
          >
            {map(seriesMap, ({ seriesName, color }) => {
              const isButtonEnabled = enabledSeries?.includes(seriesName)!;
              return (
                <FilterButton
                  key={seriesName}
                  enabled={isButtonEnabled}
                  onClick={() => periodFilter.toggleById(seriesName)}
                  label={seriesName}
                  color={
                    isButtonEnabled ? color : theme.palette.custom.defaultButton
                  }
                />
              );
            })}
          </Paper>
        </Box>
      )}
    </Box>
  );
});
