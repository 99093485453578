import React, { useContext } from 'react';
import { map } from 'lodash-es';
import { ListItemText, Typography, useTheme } from '@mui/material';
import InfoCardWrapper from 'components/reviewResultsPage/common/wrappers/infoCardWrapper';
import InfoCardHeader from 'components/reviewResultsPage/common/header/infoCardHeader';
import InfoCardContentWrapper from 'components/reviewResultsPage/common/wrappers/infoCardContentWrapper';
import IconCheckmark from 'icons/checkmark';
import ListWrapper from 'components/reviewResultsPage/common/cardLists/list/listWrapper';
import ListItemWrapper from 'components/reviewResultsPage/common/cardLists/list/listItemWrapper';
import { UiContext } from 'uiContext';
import { observer } from 'mobx-react-lite';
import { MobxContext } from 'mobx/mobxContext';

export default observer(function ResponsibilitiesCard() {
  const theme = useTheme();

  const {
    spacings: { sp },
  } = useContext(UiContext);

  const {
    mobxStore: { employeeFormStore },
  } = useContext(MobxContext);

  const { responsibilities } = employeeFormStore;

  if (!responsibilities) {
    return null;
  }

  return (
    <InfoCardWrapper>
      <InfoCardHeader
        uppercase
        icon={<IconCheckmark />}
        mainText="обязанности"
        bgColor={theme.palette.custom.infoCardHeaderColor}
      />

      <InfoCardContentWrapper>
        <ListWrapper>
          {map(responsibilities, (label, index) => (
            <ListItemWrapper isSidePaddingEnabled key={index} index={index}>
              <ListItemText>
                <Typography
                  variant="f22a"
                  sx={{
                    color: theme.palette.custom.white,
                    marginRight: sp.s10,
                  }}
                >
                  {`${index + 1}.`}
                </Typography>
                <Typography
                  variant="f22a"
                  sx={{ color: theme.palette.custom.white }}
                >
                  {label}
                </Typography>
              </ListItemText>
            </ListItemWrapper>
          ))}
        </ListWrapper>
      </InfoCardContentWrapper>
    </InfoCardWrapper>
  );
});
