import React, { useContext } from 'react';
import { Box, Typography, Paper, ThemeProvider, Theme } from '@mui/material';
import { UiContext } from 'uiContext';

type EmployeeTooltipProps = {
  theme: Theme;
  by_coworkers: number | null;
  by_lead: number | null;
  by_subordinates: number | null;
  date: string;
  value: number | null;
};

export default function EmployeeTooltip({
  theme,
  by_coworkers,
  by_lead,
  by_subordinates,
  date,
  value,
}: EmployeeTooltipProps) {
  const {
    spacings: { sp },
  } = useContext(UiContext);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <Typography
            variant="f22"
            sx={{
              color: theme.palette.custom.fontColor,
            }}
          >
            {date}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: sp.s30,
            marginTop: sp.s30,
          }}
        >
          <Box>
            <Paper
              sx={{
                backgroundColor: theme.palette.custom.headerBgColor,
                padding: sp.s40,
              }}
            >
              <Typography variant="f30">{value}</Typography>
            </Paper>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 1,
                gap: sp.s30,
              }}
            >
              <Typography
                variant="f14"
                sx={{
                  color: theme.palette.custom.fontColorNotActive,
                }}
              >
                Руководитель:
              </Typography>

              <Typography
                sx={{
                  color: theme.palette.custom.fontColor,
                }}
              >
                {by_lead ? by_lead : '-'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: sp.s30,
                width: 1,
              }}
            >
              <Typography
                variant="f14"
                sx={{
                  color: theme.palette.custom.fontColorNotActive,
                }}
              >
                Коллеги:
              </Typography>

              <Typography
                sx={{
                  color: theme.palette.custom.fontColor,
                }}
              >
                {by_coworkers ? by_coworkers : '-'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 1,
                gap: sp.s30,
              }}
            >
              <Typography
                variant="f14"
                sx={{
                  color: theme.palette.custom.fontColorNotActive,
                }}
              >
                Подчинённые:
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.custom.fontColor,
                }}
              >
                {by_subordinates ? by_subordinates : '-'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
