import { ThemeVariant } from '../mobx/storeTypes';
import each from 'lodash-es/each';
import { Breakpoint, createTheme, Theme } from '@mui/material/styles';
import { TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { pxToRem } from './uiConstants';
import {
  lightThemeParams,
  commonThemeParams,
  darkThemeParams,
} from 'assets/theme/theme';

const MEDIA_EDGE = 0;

export type TypographyVariant = keyof TypographyPropsVariantOverrides | Variant;
type GenerateResponsiveFontsOptions = {
  sizesMap: { [key in TypographyVariant]: number[] | null };
  sortedBp: Readonly<Breakpoint[]>;
};

const generateResponsiveFontsOptions: GenerateResponsiveFontsOptions = {
  sizesMap: {
    f8: null,
    f8a: null,
    f10: null,
    f10a: null,
    f12: [11, 11, 11, 11, 12, 12],
    f12a: [8, 8, 10, 11, 12, 12],
    f14: [12, 12, 12, 13, 14, 14],
    f14a: [10, 10, 10, 13, 14, 14],
    f16: [14, 14, 14, 15, 16, 16],
    f16a: [11, 11, 11, 15, 16, 16],
    f18: [14, 14, 14, 17, 18, 18],
    f18a: [12, 12, 12, 17, 18, 18],
    f20: [16, 16, 16, 18, 20, 20],
    f20a: [14, 14, 14, 18, 18, 20],
    f22: [22, 22, 16, 18, 20, 22],
    f22a: [16, 16, 16, 18, 20, 22],
    f24: [24, 24, 18, 20, 22, 24],
    f24a: [24, 24, 18, 20, 22, 24],
    f26: [26, 26, 20, 22, 24, 26],
    f26a: [26, 26, 20, 22, 24, 26],
    f28: [28, 28, 22, 24, 26, 28],
    f28a: [10, 10, 10, 16, 22, 28],
    f30: [30, 30, 24, 26, 28, 30],
    f30a: [12, 12, 12, 18, 24, 30],
    f38: [36, 36, 32, 34, 36, 38],
    f38a: [20, 20, 20, 26, 32, 38],
    f40: [38, 38, 34, 36, 38, 40],
    f40a: [22, 22, 22, 28, 34, 40],
    f50: [44, 44, 44, 46, 48, 50],
    f50a: [32, 32, 32, 38, 44, 50],
    f70: [64, 64, 64, 66, 68, 70],
    f70a: [52, 52, 52, 58, 64, 70],
    f80: [74, 74, 74, 76, 78, 80],
    f80a: [60, 60, 60, 66, 72, 80],
    h1: null,
    h2: null,
    h3: null,
    h4: null,
    h5: null,
    h6: null,
    subtitle1: null,
    subtitle2: null,
    body1: null,
    body2: null,
    caption: null,
    button: null,
    overline: null,
  },
  // При min-width обязателен порядок по возрастанию!
  sortedBp: [
    'mobile',
    'tablet',
    'laptop',
    'desktop_small',
    'desktop_middle',
    'desktop_large',
  ],
};

const generateResponsiveFonts2 = (
  theme: Theme,
  { sizesMap, sortedBp }: GenerateResponsiveFontsOptions
) => {
  each(sortedBp, (bp, bpIndex) => {
    const mediaString = theme.breakpoints.up(bp);
    const bpWidth = theme.breakpoints.values[bp];

    if (bpWidth <= MEDIA_EDGE) {
      return;
    }

    // Заглушка от нуля до нижнего брикпоинта
    each(sizesMap, (values, key) => {
      const mediaString = theme.breakpoints.up(0);
      const variant = key as TypographyVariant;

      if (!values) {
        const baseSize = theme.typography[variant].fontSize;
        // @ts-ignore
        theme.typography[variant][mediaString] = {
          fontSize: baseSize,
        };
        return;
      }

      // @ts-ignore
      theme.typography[variant][mediaString] = {
        fontSize: `${pxToRem(values[0])}rem`,
      };
    });

    each(sizesMap, (values, key) => {
      const variant = key as TypographyVariant;

      if (!values) {
        const baseSize = theme.typography[variant].fontSize;
        // @ts-ignore
        theme.typography[variant][mediaString] = {
          fontSize: baseSize,
        };
        return;
      }

      // @ts-ignore
      theme.typography[variant][mediaString] = {
        fontSize: `${pxToRem(values[bpIndex])}rem`,
      };
    });
  });

  return theme;
};

export const constructTheme = (variant: ThemeVariant) => {
  switch (variant) {
    case ThemeVariant.LIGHT:
      return generateResponsiveFonts2(
        createTheme({ ...lightThemeParams, ...commonThemeParams }),
        generateResponsiveFontsOptions
      );
    case ThemeVariant.DARK:
      return generateResponsiveFonts2(
        createTheme({ ...darkThemeParams, ...commonThemeParams }),
        generateResponsiveFontsOptions
      );
  }
};
