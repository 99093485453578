import { DashboardData } from 'mobx/storeTypes';
import { forEach, map, toPairs, isEmpty, pick } from 'lodash-es';
import {
  ColumnChartParams,
  ColumnChartSeriesType,
} from 'components/columnChart/columnChartTypes';
import {
  getEmptyColumnChartData,
  addDataLabelsToChartSeries,
  addPlotBands,
} from 'utils/dataAdapters/chartAdapters';
import { formatDate } from 'utils/dateFormat';
import { getDateColor } from 'utils/dataMappings';

type EmployeePreparedItem = {
  color: string;
  by_coworkers: number | null;
  by_lead: number | null;
  by_subordinates: number | null;
  name: string;
  start_date: string;
  y: number | null;
};

export const getEmployeeInfoDataAdapted = (
  data: DashboardData.EmployeeInfo
) => {
  if (isEmpty(data)) {
    return getEmptyColumnChartData();
  }

  const prerapedMap: Record<string, EmployeePreparedItem[]> = {};

  const statistics = data.statistics;

  forEach(statistics, (statisticsItem) => {
    const start_date = formatDate(statisticsItem.start_date);

    const item = {
      ...pick(statisticsItem, ['by_coworkers', 'by_lead', 'by_subordinates']),
      y: statisticsItem.value,
      color: getDateColor(start_date),
      start_date,
      name: 'Средняя оценка',
    };

    if (start_date in prerapedMap) {
      prerapedMap[start_date].push(item);
    } else {
      prerapedMap[start_date] = [];
      prerapedMap[start_date].push(item);
    }
  });

  const entriesFromMap = toPairs(prerapedMap);
  const preparedData: Array<ColumnChartSeriesType> = map(
    entriesFromMap,
    ([label, dataItems]) => {
      const newItem = {
        name: label,
        dataLabels: addDataLabelsToChartSeries(),
        data: dataItems,
      };

      return newItem;
    }
  );

  const xAxisCategories = [data.employee_name];

  return {
    series: preparedData,
    axisParams: {
      xAxisCategories,
      plotBands: addPlotBands(xAxisCategories),
    },
  } as ColumnChartParams;
};
