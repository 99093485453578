import { Breakpoint, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

export const defaultSpacingPresets = {
  sp: {
    s240: 24,
    s220: 22,
    s200: 20,
    s170: 17,
    s120: 12,
    s100: 10,
    s80: 8,
    s70: 7,
    s60: 6,
    s50: 5,
    s40: 4,
    s30: 3,
    s25: 2.5,
    s20: 2,
    s15: 1.5,
    s10: 1,
  },
  main: {
    x: 5,
    y: 6,
  },
  other: {
    s80: 8,
    s70: 7,
    s60: 6,
    s50: 5,
    s40: 4,
    s30: 3,
    s25: 2.5,
    s20: 2,
    s15: 1.5,
    s10: 1,
  },
  header: {
    height: 60,
    logoW: 127,
    logoH: 32,
  },
  controls: {
    checkbox: {
      w: 19,
      h: 19,
    },
    // Это эмперический замер path внутри чекбоксовой svg, нужно для расчета отступов
    checkboxInner: {
      w: 14.25,
      h: 14.25,
    },
    switch: {
      w: 32,
      h: 16,
    },
    appg: {
      w: 44,
      h: 16,
    },
  },
  icons: {
    '6x6': {
      w: 6,
      h: 6,
    },
    '8x8': {
      w: 8,
      h: 8,
    },
    '10x10': {
      w: 10,
      h: 10,
    },
    '12x12': {
      w: 12,
      h: 12,
    },
    '14x14': {
      w: 14,
      h: 14,
    },
    '16x16': {
      w: 16,
      h: 16,
    },
    '20x20': {
      w: 20,
      h: 20,
    },
    '24x24': {
      w: 24,
      h: 24,
    },
    '27x16': {
      w: 27,
      h: 16,
    },
    '32x32': {
      w: 32,
      h: 32,
    },
    '38x38': {
      w: 38,
      h: 38,
    },
    '64x32': {
      w: 64,
      h: 32,
    },
    '60x60': {
      w: 60,
      h: 60,
    },
    '100x125': {
      w: 100,
      h: 125,
    },
  },
};

export type IconsSizes = keyof (typeof defaultSpacingPresets)['icons'];

const generateProportionalPreset = (proportion: number) => {
  return {
    sp: {
      s240: defaultSpacingPresets.sp.s240 / proportion,
      s220: defaultSpacingPresets.sp.s220 / proportion,
      s200: defaultSpacingPresets.sp.s200 / proportion,
      s170: defaultSpacingPresets.sp.s170 / proportion,
      s120: defaultSpacingPresets.sp.s120 / proportion,
      s100: defaultSpacingPresets.sp.s100 / proportion,
      s80: defaultSpacingPresets.sp.s80 / proportion,
      s70: defaultSpacingPresets.sp.s70 / proportion,
      s60: defaultSpacingPresets.sp.s60 / proportion,
      s50: defaultSpacingPresets.sp.s50 / proportion,
      s40: defaultSpacingPresets.sp.s40 / proportion,
      s30: defaultSpacingPresets.sp.s30 / proportion,
      s25: defaultSpacingPresets.sp.s25 / proportion,
      s20: defaultSpacingPresets.sp.s20 / proportion,
      s15: defaultSpacingPresets.sp.s15 / proportion,
      s10: defaultSpacingPresets.sp.s10 / proportion,
    },
    main: {
      x: defaultSpacingPresets.main.x / proportion,
      y: defaultSpacingPresets.main.y / proportion,
    },
    header: {
      height: defaultSpacingPresets.header.height / proportion,
      logoW: defaultSpacingPresets.header.logoW / proportion,
      logoH: defaultSpacingPresets.header.logoH / proportion,
    },
    other: {
      s80: defaultSpacingPresets.other.s80 / proportion,
      s70: defaultSpacingPresets.other.s70 / proportion,
      s60: defaultSpacingPresets.other.s60 / proportion,
      s50: defaultSpacingPresets.other.s50 / proportion,
      s40: defaultSpacingPresets.other.s40 / proportion,
      s30: defaultSpacingPresets.other.s30 / proportion,
      s25: defaultSpacingPresets.other.s25 / proportion,
      s20: defaultSpacingPresets.other.s20 / proportion,
      s15: defaultSpacingPresets.other.s15 / proportion,
      s10: defaultSpacingPresets.other.s10 / proportion,
    },
    controls: {
      checkbox: {
        w: defaultSpacingPresets.controls.checkbox.w / proportion,
        h: defaultSpacingPresets.controls.checkbox.h / proportion,
      },
      checkboxInner: {
        w: defaultSpacingPresets.controls.checkboxInner.w / proportion,
        h: defaultSpacingPresets.controls.checkboxInner.h / proportion,
      },
      switch: {
        w: defaultSpacingPresets.controls.switch.w / proportion,
        h: defaultSpacingPresets.controls.switch.h / proportion,
      },
      appg: {
        w: defaultSpacingPresets.controls.appg.w / proportion,
        h: defaultSpacingPresets.controls.appg.h / proportion,
      },
    },
    icons: {
      '6x6': {
        w: defaultSpacingPresets.icons['6x6'].w / proportion,
        h: defaultSpacingPresets.icons['6x6'].h / proportion,
      },
      '8x8': {
        w: defaultSpacingPresets.icons['8x8'].w / proportion,
        h: defaultSpacingPresets.icons['8x8'].h / proportion,
      },
      '12x12': {
        w: defaultSpacingPresets.icons['12x12'].w / proportion,
        h: defaultSpacingPresets.icons['12x12'].h / proportion,
      },
      '14x14': {
        w: defaultSpacingPresets.icons['14x14'].w / proportion,
        h: defaultSpacingPresets.icons['14x14'].h / proportion,
      },
      '10x10': {
        w: defaultSpacingPresets.icons['10x10'].w / proportion,
        h: defaultSpacingPresets.icons['10x10'].h / proportion,
      },
      '16x16': {
        w: defaultSpacingPresets.icons['16x16'].w / proportion,
        h: defaultSpacingPresets.icons['16x16'].h / proportion,
      },
      '20x20': {
        w: defaultSpacingPresets.icons['20x20'].w / proportion,
        h: defaultSpacingPresets.icons['20x20'].h / proportion,
      },
      '24x24': {
        w: defaultSpacingPresets.icons['24x24'].w / proportion,
        h: defaultSpacingPresets.icons['24x24'].h / proportion,
      },
      '27x16': {
        w: defaultSpacingPresets.icons['27x16'].w / proportion,
        h: defaultSpacingPresets.icons['27x16'].h / proportion,
      },
      '32x32': {
        w: defaultSpacingPresets.icons['32x32'].w / proportion,
        h: defaultSpacingPresets.icons['32x32'].h / proportion,
      },
      '38x38': {
        w: defaultSpacingPresets.icons['38x38'].w / proportion,
        h: defaultSpacingPresets.icons['38x38'].h / proportion,
      },
      '64x32': {
        w: defaultSpacingPresets.icons['64x32'].w / proportion,
        h: defaultSpacingPresets.icons['64x32'].h / proportion,
      },
      '60x60': {
        w: defaultSpacingPresets.icons['60x60'].w / proportion,
        h: defaultSpacingPresets.icons['60x60'].h / proportion,
      },
      '100x125': {
        w: defaultSpacingPresets.icons['100x125'].w / proportion,
        h: defaultSpacingPresets.icons['100x125'].h / proportion,
      },
    },
  };
};

// Отступы используемые повсеместно, которые должны быть
// Одинаковыми во всех компонентах и зависят от текущего размера экрана
export const useGetSpacing = ({ currentBp }: { currentBp: Breakpoint }) => {
  const theme = useTheme();

  const [proportions] = useState({
    desktop_large:
      theme.breakpoints.values.desktop_middle /
      theme.breakpoints.values.desktop_large,
    desktop_middle: 1,
    desktop_small:
      theme.breakpoints.values.desktop_middle /
      theme.breakpoints.values.desktop_small,
    laptop:
      theme.breakpoints.values.desktop_small / theme.breakpoints.values.laptop,
  });

  const [largePreset] = useState(
    generateProportionalPreset(proportions['desktop_large'])
  );
  const [smallPreset] = useState(
    generateProportionalPreset(proportions['desktop_small'])
  );
  const [laptopPreset] = useState(
    generateProportionalPreset(proportions['laptop'])
  );

  const [spacingPreset, setSpacingPreset] = useState(defaultSpacingPresets);

  useEffect(() => {
    switch (currentBp) {
      case 'desktop_large':
        setSpacingPreset(largePreset);
        break;
      case 'desktop_middle':
        setSpacingPreset(defaultSpacingPresets);
        break;
      case 'desktop_small':
        setSpacingPreset(smallPreset);
        break;
      case 'laptop':
        setSpacingPreset(laptopPreset);
        break;
      default:
        setSpacingPreset(defaultSpacingPresets);
        break;
    }
  }, [currentBp, theme, laptopPreset, smallPreset, largePreset]);

  return spacingPreset;
};
