import React from 'react';
import { TableCell, SxProps, Typography, useTheme } from '@mui/material';

export default function TableCellWrapper({
  text,
  align = 'center',
  typographySx,
  cellSx,
}: {
  text: string | number;
  align?: 'left' | 'center' | 'right';
  cellSx?: SxProps;
  typographySx?: SxProps;
}) {
  const theme = useTheme();

  return (
    <TableCell
      align={align}
      sx={{
        color: theme.palette.custom.white,
        borderBottom: 'none',
        ...cellSx,
      }}
    >
      <Typography variant="f20a" sx={{ fontWeight: 'bold', ...typographySx }}>
        {text}
      </Typography>
    </TableCell>
  );
}
