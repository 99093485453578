import React from 'react';
import ButtonComponent from 'controls/buttons/buttonComponent/buttonComponent';

export default function FilterButton({
  label,
  enabled,
  onClick,
  color,
}: {
  label: string;
  enabled: boolean;
  color: string;
  onClick: () => void;
}) {
  return (
    <ButtonComponent enabled={enabled} color={color} onClick={onClick}>
      {label}
    </ButtonComponent>
  );
}
