import React from 'react';
import { Box, SxProps, Table } from '@mui/material';

export default function TableWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <Box sx={{ overflow: 'auto' }}>
      <Table sx={{ ...sx }}>{children}</Table>
    </Box>
  );
}
