import React, { useContext } from 'react';
import { IconProps } from './iconProps';
import { UiContext } from 'uiContext';
import { defaultSpacingPresets } from 'hooks/useGetSpacing';
import cx from 'classnames';

export default function IconPaper({
  onClick,
  isAutoscallable = true,
  sizeKey = '100x125',
  style,
}: IconProps) {
  const {
    spacings: {
      icons: { [sizeKey]: size },
    },
  } = useContext(UiContext);

  const w = isAutoscallable ? size.w : defaultSpacingPresets.icons[sizeKey].w;
  const h = isAutoscallable ? size.h : defaultSpacingPresets.icons[sizeKey].h;

  return (
    <svg
      style={style}
      className={cx('icon IconPaper', { 'icon-disabled': !onClick })}
      onClick={onClick}
      width={w}
      height={h}
      viewBox="0 0 101 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.5302 0H40.7212V28.6092C40.7212 29.1968 40.6053 29.7787 40.3802 30.3216C40.155 30.8644 39.825 31.3577 39.409 31.7732C38.993 32.1887 38.4991 32.5183 37.9555 32.7431C37.412 32.968 36.8294 33.0837 36.241 33.0837H0V122.532C0 123.719 0.472015 124.857 1.31221 125.696C2.1524 126.535 3.29195 127.007 4.48016 127.007H96.5302C97.7184 127.007 98.8579 126.535 99.6981 125.696C100.538 124.857 101.01 123.719 101.01 122.532V4.4745C101.01 3.28779 100.538 2.14968 99.6981 1.31055C98.8579 0.471419 97.7184 0 96.5302 0Z"
        fill="#955CD6"
      />
      <path
        d="M35.8068 24.596V0L17.9827 14.4044L0 28.8123H31.5851C32.7045 28.8114 33.7778 28.3669 34.5693 27.5764C35.3608 26.7859 35.8059 25.7139 35.8068 24.596Z"
        fill="#955CD6"
      />
    </svg>
  );
}
