import React, { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { UiContext } from 'uiContext';

export default function InfoCardHeader({
  icon,
  mainText,
  mainTextSecondLine,
  lowerText,
  bgColor,
  score,
  uppercase,
}: {
  icon?: React.ReactNode;
  mainText: string;
  mainTextSecondLine?: string;
  lowerText?: string;
  bgColor: string;
  score?: number | string;
  uppercase?: boolean;
}) {
  const theme = useTheme();
  const {
    spacings: { sp },
  } = useContext(UiContext);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: sp.s120,
        paddingRight: sp.s240,
        paddingTop: sp.s100,
        paddingBottom: sp.s100,
        color: theme.palette.custom.white,
        background: bgColor,
      }}
    >
      {icon && <Box sx={{ marginRight: sp.s25 }}>{icon}</Box>}
      <Box
        sx={{
          color: theme.palette.custom.white,
          display: 'flex',
          justifyContent: score ? 'space-between' : 'flex-start',
          alignItems: 'center',
          width: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="f40a"
            sx={{ textTransform: uppercase ? 'uppercase' : 'none' }}
          >
            {mainText}
          </Typography>
          {mainTextSecondLine && (
            <Typography variant="f40a">{mainTextSecondLine}</Typography>
          )}
          {lowerText && <Typography variant="f18a">{lowerText}</Typography>}
        </Box>

        {score && (
          <Box>
            <Typography variant="f50a">{score}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
