import React from 'react';
import { SxProps, TableHead, TableRow } from '@mui/material';

export default function TableHeaderWrapper({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  return (
    <TableHead>
      <TableRow sx={sx}>{children}</TableRow>
    </TableHead>
  );
}
