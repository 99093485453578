import axios from 'axios';

export const APIV1BaseURL =
  window.location.hostname === 'localhost'
    ? `${process.env.REACT_APP_API_DOMAIN}/api/v1/`
    : `${process.env.REACT_APP_API_DOMAIN}/api/v1/`;

export const axiosV1 = axios.create();
axiosV1.defaults.baseURL = APIV1BaseURL;

export const setToken = (token: string) => {
  axiosV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};
