import React, { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import IconICLogo from 'icons/ICLogo';
import IconMskTransportLogo from 'icons/MskTransportLogo';
import { UiContext } from 'uiContext';
import { IconsSizes } from 'hooks/useGetSpacing';

export default function Header({ sizeKey }: { sizeKey?: IconsSizes }) {
  const theme = useTheme();

  const {
    spacings: { sp },
  } = useContext(UiContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.custom.headerBgColor,
        padding: sp.s40,
        gap: sp.s10,
      }}
    >
      <Box>
        <IconICLogo sizeKey={sizeKey} />
      </Box>
      <Box>
        <IconMskTransportLogo sizeKey={sizeKey} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="f14">Безопасный</Typography>
        <Typography variant="f14">транспорт</Typography>
      </Box>
    </Box>
  );
}
