import React, { useState, useRef, useEffect } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as HighchartsHighstock from 'highcharts/highstock';
import ChartStyleWrapper from './chartStyleWrapper';
import { ColumnChartParams } from './columnChartTypes';
import {
  useConstructAxisOptions,
  useConstructPlotOptions,
  useConstructTooltipOptions,
  PlotOptionsParams,
  TooltipOptionsParams,
} from './hooks';

export default function ColumnChart({
  chartData,
  plotOptionsArgs,
  tooltipOptionsArgs,
}: {
  chartData: ColumnChartParams;
  plotOptionsArgs?: PlotOptionsParams;
  tooltipOptionsArgs?: TooltipOptionsParams;
}) {
  const ref = useRef<HighchartsReact.RefObject | null>(null);

  const xAxisOptions = useConstructAxisOptions(chartData.axisParams);
  const plotOptions = useConstructPlotOptions({ ...plotOptionsArgs });
  const tooltipOptions = useConstructTooltipOptions({ ...tooltipOptionsArgs });

  const [options, setOptions] = useState<Highcharts.Options>(() => {
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
      },
      title: {
        style: {
          display: 'none',
        },
      },
      legend: {
        enabled: false,
      },
      ...xAxisOptions,
      plotOptions: plotOptions,
      tooltip: tooltipOptions,
      credits: {
        enabled: false,
      },
      series: chartData.series,
    };
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      ...xAxisOptions,
      series: chartData.series,
    }));
  }, [chartData, xAxisOptions]);

  return (
    <ChartStyleWrapper>
      <HighchartsReact
        ref={ref}
        highcharts={
          chartData.axisParams.isScrollEnabled
            ? HighchartsHighstock
            : Highcharts
        }
        options={options}
      />
    </ChartStyleWrapper>
  );
}
